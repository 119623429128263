import moment from "moment";
import { useState, useEffect, useMemo, Fragment } from "react";
import React from "react";
import { Form, Button } from "react-bootstrap";
import './Review.css';
import './Review.scss';
import { FaSearch } from "react-icons/fa";
import token from './Token';
import _ from 'lodash';
import { Bar } from 'react-chartjs-2';
import { ReviewCalendarViewChart } from './ReviewCalendarViewChart';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

import {
    Calendar,
    Views,
    DateLocalizer,
    momentLocalizer,
  } from 'react-big-calendar'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);


const ColoredDateCellWrapper = ({ children }) =>
React.cloneElement(React.Children.only(children), {
    style: {
    backgroundColor: 'lightblue',
    },
})

const mLocalizer = momentLocalizer(moment)


function Review() {

    const localizer = mLocalizer

    const [fromDate, setFromDate] = useState(moment().add(-1, 'months').startOf('day').set('hour', 2).format('YYYY-MM-DD HH:mm'));
    const [toDate, setToDate] = useState(moment().startOf('day').add(1, 'days').set('hour', 2).format('YYYY-MM-DD HH:mm'));

    const [data, setData] = useState([]);
    const [stats, setStats] = useState({});
    const [groupedByDate, setGroupedByDate] = useState({
        labels: ["01/01/2024", "02/01/2024", "03/01/2024"],
        datasets: [{
            label: 'Duration (minutes)',
            data: [10, 20, 30],
            backgroundColor: 'rgba(75, 192, 192, 0.6)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
        }]
    });

    const eventFormatCast = (data) => {
        return data.map((item, index) => {
            const endDate = new Date(item.datetime);
            const startDate = new Date(endDate.getTime() - item.duration * 60000); // Convert duration to milliseconds

            return {
                id: index + 1,
                title: `${item.taskId}: ${item.note}`,
                start: startDate,
                end: endDate,
                duration: item.duration
            };
        });
    };

    const groupByDate = (rawData) => {
        const groupedData = rawData.reduce((acc, item) => {
            const date = moment(item.datetime).format('DD/MM/YYYY');
            if (!acc[date]) {
                acc[date] = 0;
            }
            acc[date] += item.duration;
            return acc;
        }, {});

        return groupedData;
    };

    const queryTimerHistory = async () => {
        try {
            const response = await fetch(`https://paymemobile.fr/getTimerHistoryForCalendarView?token=${token}&datetime=${fromDate}`);
            const rawData = await response.json();
            
            const formattedData = eventFormatCast(rawData);
            // console.log(rawData);
            setData(formattedData);
            setStats(groupByTaskId(rawData));
            // console.log(groupByTaskId(rawData));
            setGroupedByDate(groupByDate(rawData));
            console.log(groupByDate(rawData));
        } catch (error) {
            console.error("Error fetching timer history:", error);
        }
    }

    

    const setQuickDateRange = (mode) => {
        switch (mode) {
            case 'today':
                setFromDate(moment().startOf('day').add(2, 'hours').format('YYYY-MM-DD HH:mm'));
                setToDate(moment().endOf('day').add(2, 'hours').format('YYYY-MM-DD HH:mm'));
                break;
            case 'yesterday':
                setFromDate(moment().subtract(1, 'days').startOf('day').add(2, 'hours').format('YYYY-MM-DD HH:mm'));
                setToDate(moment().subtract(1, 'days').endOf('day').add(2, 'hours').format('YYYY-MM-DD HH:mm'));
                break;
            case 'this week':
                setFromDate(moment().startOf('week').add(2, 'hours').format('YYYY-MM-DD HH:mm'));
                setToDate(moment().endOf('week').add(2, 'hours').format('YYYY-MM-DD HH:mm'));
                break;
            case 'last week':
                setFromDate(moment().subtract(1, 'weeks').startOf('week').add(2, 'hours').format('YYYY-MM-DD HH:mm'));
                setToDate(moment().subtract(1, 'weeks').endOf('week').add(2, 'hours').format('YYYY-MM-DD HH:mm'));
                break;
            case 'this month':
                setFromDate(moment().startOf('month').add(2, 'hours').format('YYYY-MM-DD HH:mm'));
                setToDate(moment().endOf('month').add(2, 'hours').format('YYYY-MM-DD HH:mm'));
                break;
            case 'last month':
                setFromDate(moment().subtract(1, 'months').startOf('month').add(2, 'hours').format('YYYY-MM-DD HH:mm'));
                setToDate(moment().subtract(1, 'months').endOf('month').add(2, 'hours').format('YYYY-MM-DD HH:mm'));
                break;
            default:
                console.error('Invalid mode');
        }
    }

    const { components, defaultDate, max, views } = useMemo(
        () => ({
          components: {
            timeSlotWrapper: ColoredDateCellWrapper,
          },
          defaultDate: new Date(),
          max: moment().add(7, 'days').endOf('day').toDate(),
          views: Object.keys(Views).map((k) => Views[k]),
        }),
        []
    )

    const groupByTaskId = (data) => {
        let totalMinutes = 0;
        let mData = _.cloneDeep(data);
        // Filter mData based on fromDate and toDate
        mData = mData.filter(item => {
            const itemDate = moment(item.datetime);
            return itemDate.isSameOrAfter(moment(fromDate)) && itemDate.isSameOrBefore(moment(toDate));
        });

        const groupedData = mData.reduce((acc, item) => {
          if (!acc[item.taskId]) {
            acc[item.taskId] = 0;
          }
          acc[item.taskId] += item.duration;
          totalMinutes += item.duration;
          return acc;
        }, {});

        // Convert minutes to a readable format (HH:MM)
        const formatDuration = (minutes) => {
            // convert minutes to integer
            minutes = parseInt(minutes);
            const hours = Math.floor(minutes / 60);
            const remainingMinutes = minutes % 60;
            return `${hours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}`;
        };

    
        return {
            totalMinutes: formatDuration(totalMinutes),
            data: Object.entries(groupedData).map(([taskId, duration]) => ({
                taskId,
                duration: Math.round(duration), // Rounding to nearest minute
                durationStr: formatDuration(Number(duration))
            }))
        };
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Daily Time Spent',
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Duration (minutes)',
                },
            },
        },
    };



    return (
        <div className="AppRootDesktop">
            <div className='AppRootInnerOverlay'>
                <div className='BodyEdit'>
                    <div className='SchedulePicker'>
                        <h1>Calendar Review</h1>
                    </div>
                    <div className='TimeRangePicker'>
                        <Form.Label>From</Form.Label>
                        <Form.Control type="datetime" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
                        <Form.Label>To</Form.Label>
                        <Form.Control type="datetime" value={toDate} onChange={(e) => setToDate(e.target.value)} />
                        <div className="QuickDateRangePicker">
                            <div className="button-grid">
                                <div className="button-cell">
                                    <Button variant="outline-primary" className="w-100" onClick={() => setQuickDateRange('today')}>Today</Button>
                                </div>
                                <div className="button-cell">
                                    <Button variant="outline-primary" className="w-100" onClick={() => setQuickDateRange('yesterday')}>Yesterday</Button>
                                </div>
                                <div className="button-cell">
                                    <Button variant="outline-primary" className="w-100" onClick={() => setQuickDateRange('this week')}>This Week</Button>
                                </div>
                                <div className="button-cell">
                                    <Button variant="outline-primary" className="w-100" onClick={() => setQuickDateRange('last week')}>Last Week</Button>
                                </div>
                                <div className="button-cell">
                                    <Button variant="outline-primary" className="w-100" onClick={() => setQuickDateRange('this month')}>This Month</Button>
                                </div>
                                <div className="button-cell">
                                    <Button variant="outline-primary" className="w-100" onClick={() => setQuickDateRange('last month')}>Last Month</Button>
                                </div>
                            </div>
                        </div> {/* QuickDateRangePicker */}
                        <div className="DataControls">
                            <Button variant="success" onClick={queryTimerHistory}><FaSearch /> Search</Button>
                        </div>
                        <div className="StatsDivvv">
                            {stats.data && stats.data.length > 0 ? stats.data.map((item) => (
                                <div key={item.taskId}>{item.taskId}: {item.durationStr}</div>
                            )) : <></>}
                            <div className="TotalMinutes">Total: {stats.totalMinutes}</div>
                        </div>

                        <div className="GroupedByDateDivvv">
                            <ReviewCalendarViewChart groupedData={groupedByDate} />
                        </div>

                        <div className="CalendarDivvv">
                        <Fragment>
                            <Calendar
                                components={components}
                                defaultDate={defaultDate}
                                events={data}
                                localizer={localizer}
                                max={max}
                                showMultiDayTimes
                                step={15}
                                views={views}
                                popup={true}
                                defaultView="week"
                                />
                        </Fragment>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Review;
