import "./Home.css"
import Button from 'react-bootstrap/Button'
import { ProgressBar } from "react-bootstrap";
import { useEffect, useState, useRef, useCallback } from "react"
// import { collection, getDocs, query, doc, setDoc, getDoc} from "firebase/firestore";
import { getDocsx, docx, setDocx, collectionx, queryx } from "./SubtaskNewServer"
import db from './Firebase'
import moment from 'moment'
import Calendar from './Calendar'
import { useSelector, useDispatch } from "react-redux";
import {BsPlayCircleFill, BsPauseCircleFill, BsCheckCircleFill, BsFillBackspaceFill, BsArrowClockwise} from 'react-icons/bs'
import { BiTask } from "react-icons/bi";
import {MdTimerOff, MdTimer, MdCalendarToday} from 'react-icons/md'

import {AiOutlineCaretDown, AiOutlineCaretUp, AiFillCaretUp, AiFillWarning, AiOutlineFlag, AiOutlineCoffee} from 'react-icons/ai'
import payrate from './payrate'
import Habits from './Habits'
import Todos from './Todos'
import AllTasksNew from "./AllTasksNew";
import Notifications from "./NotificationsNew";
import { setGlobalClock, setNetworkOk, setTaskCount, setMqttConnected, setCloudSyncing, setUnsavedChanges,
    setNotifications, setRefreshAdvised, setShowEmotionalEmergencyChecklist } from './calendarReducers'
import Ticker from "./Ticker";
import TickerList from "./TickerList";
// import Notis from "./Notis";

// import { redirect } from "react-router-dom";
// import PersonalCalendarSideBar from "./PersonalCalendarSideBar";
import CalendarAnimatedList from "./CalendarAnimatedList";
import Checklist from "./Checklist";

import Gauge from "./Gauge";

import mqtt from "precompiled-mqtt";

import _ from 'lodash'
import convertToHourMinute from "./ConvertToHourMinute";
import RemainingTasksHome from "./RemainingTasksHome";
// import DailyCalendarSideBar from "./DailyCalendarSideBar";
import HomeControlPanel from "./HomeControlPanel";
import TimerControlHistory from "./TimerControlHistory";


import ActiveStamp from "./ActiveStamp";
import WeatherDashboard from "./WeatherDashboard";
import TaskSwitcher from "./TaskSwitcher";

import { queryNeedToFeedback } from "./FeedbackHelpers";
import Feedback from "./Feedback";

import token from "./Token";
import mqttUsername from "./MqttUsername";
import Advice from "./Advice";
import { getRemainingDays } from "./DaysUntilEvent";
import CalendarDayView from "./CalendarDayView";
import TickerTask from "./TickerTask";

// import formatDuration from "./FormatDuration";
// import formatDurationWithSeconds from "./formatDurationWithSeconds";
import Chronometer from "./Chronometer";
import TaskCount from "./TaskCount";
import { FaCoffee, FaRegHourglass, FaWalking } from "react-icons/fa";
import ProfilePic from "./ProfilePic";
import username from "./MqttUsername";
import { fetchBalance, syncBalance } from "./balanceUtils";


console.log = function() {} // disable console.log

function Home({showSeconds, swapped})
{
    const dispatch = useDispatch()

    // MQTT control link ========================
    const mqttConnect = () => {
        const client = mqtt.connect("wss://paymemobile.fr:9001", {
            clientId: "web_" + Math.random().toString(16).substr(2, 8),
            username: "root",
            password: "tQ@*9XXiJZ^G$v",
            reconnectPeriod: 3000,
            connectTimeout: 10000,
        });
        // MQTT client setup
        client.on("connect", () => {
            console.log("MQTT connected");
            dispatch(setMqttConnected({mqttConnected: true}))
            // alert("Subscribed to control channel " + "control_" + mqttUsername)
            client.subscribe("control_" + mqttUsername, (err) => {
                if (err) {
                console.log("MQTT subscribe to control channel error: ", err);
                }
            });
        });
    
        client.on("message", (topic, message) => {
            console.log("MQTT message received: ", topic, message.toString());
            if (topic === "control_" + mqttUsername) {
                if (message.toString() === "tasks") {
                    setNextSubTaskUpdate(moment().add(3, 'second').toISOString())
                    dispatch(setCloudSyncing({cloudSyncing: true}))
                }
                if (message.toString() === "todos") {
                    setTodoRefreshFlag(x => x + 1)
                    dispatch(setCloudSyncing({cloudSyncing: true}))
                }

            }
        });

        client.on("error", (err) => {
            console.log("MQTT error: ", err);
        });

        client.on("close", () => {
            console.log("MQTT connection closed");
            dispatch(setMqttConnected(false))
        });
        return client;
    }

  // ===========================================

    const [dailySn, setDailySn] = useState('')
    const [subtasks, setSubtasks] = useState([])
    // const [timeReport, setTimeReport] = useState([])
    const [currentSubSub, setCurrentSubSub] = useState({sn: ""})
    const [timerHandle, setTimerHandle] = useState(null)

    const firstTimeRender = useRef(true)

    const [locked, setLocked] = useStickyState(false, 'locked')

    const [defaultTimer1, setDefaultTimer1] = useState(25 * 60 * 1000 - 30 * 1000)
    const [defaultTimer2, setDefaultTimer2] = useState(1 * 60 * 1000)
    
    const [persistedTimer1, setPersistedTimer1] = useStickyState(25 * 60 * 1000 - 30 * 1000, 'persistedTimer1')
    const [persistedTimer2, setPersistedTimer2] = useStickyState(1 * 60 * 1000, 'persistedTimer2')

    const [timer1, setTimer1] = useState(defaultTimer1)
    const [timer1Memo, setTimer1Memo] = useState(defaultTimer1) // to memoize the timer1 value to avoid rerendering of HomeControlPanel
    const [timer2, setTimer2] = useState(defaultTimer2)
    const [timer1InitialValue, setTimer1InitialValue] = useState(defaultTimer1)
    const [timer1InitialValue2, setTimer1InitialValue2] = useState(defaultTimer1)

    const [remainingTimeOfTheStamp, setRemainingTimeOfTheStamp] = useState(0)
    const [minuteCountedOfTheStamp, setMinuteCountedOfTheStamp] = useState(0)
    // timer1 is just something to show to the user interface, because we use the clock to calculate the remaining time
    // we need to calculate the elapsed time since user press "Start"
    // timer1InitialValue2 serves such purpose
    // timer1InitialValue is used to calculate the "live" remaining time for each task, total tasks (displayed on the task list)
    // it is also used to log the time into timer history
    // const [chronometer1, setChronometer1] = useState(0)
    const [idleSince, setIdleSince] = useState(moment().toISOString()) // replace chronometer1

    // count up mode: instead of displaying remaining time, we display the elapsed time
    const [countUpMode, setCountUpMode] = useStickyState(false, 'countUpMode')

    const [timerStartAt, setTimerStartAt] = useState(moment().toISOString())
    const [timerSignal, setTimerSignal] = useState(0)
    const [timerActive, setTimerActive] = useState(1)

    const [timerHistory, setTimerHistory] = useStickyState([], 'timerHistory') // local data, not yet synced to server and persisted
    
    const [rightPanelMode, setRightPanelMode] = useState(0) // 0: tasks, personal calendar, daily calendar

    // Redux states
    // const dailyCalendar = useSelector(state => state.calendar.daily) 
    const neTimeDaily = useSelector(state => state.calendar.neTimeDaily)
    const neTimePersonal = useSelector(state => state.calendar.neTimePersonal)

    const imminentCalendarBar = useSelector(state => state.calendar.imminentCalendarBar)

    const allTodos = useSelector(state => state.calendar.todos) // show these todos when the screen is idle
    const [todoRefreshFlag, setTodoRefreshFlag] = useState(0)
    // Timer state for calendar update
    const [currentTime, setCurrentTime] = useState(moment().toISOString())

    const [showTimeInsteadOfTimer, setShowTimeInsteadOfTimer] = useState(false)
    const [weather, setWeather] = useState({
        temp: 0,
        humidity: 0,
        description: '',
        uvIndex: 0,
        wind: 0,
        sunrise: '',
        sunset: ''
    })
    const [nextWeatherUpdate, setNextWeatherUpdate] = useState(moment().add(5, 'minute').toISOString())
    const [nextSubTaskUpdate, setNextSubTaskUpdate] = useState(moment().add(5, 'minute').toISOString())

    const totalTaskCount = useSelector(state => state.calendar.taskCount)

    // Total minutes remaining
    const [hourRemaining, setHourRemaining] = useState(0)
    const [hourRemainingToDisplay, setHourRemainingToDisplay] = useState(0)
    const [totalHourOfTheStamp, setTotalHourOfTheStamp] = useState(0)

    const [displayTimerOptions, setDisplayTimerOptions] = useState(true)
    const [showElapsedTimeOfThisStamp, setShowElapsedTimeOfThisStamp] = useState(false)

    const [subtasksHighlighted, setSubtasksHighlighted] = useState("")
    const [showProcastTips, setShowProcastTips] = useState(false)

    function useStickyState(defaultValue, key) {
        const [value, setValue] = useState(() => {
            const stickyValue = window.localStorage.getItem(key);
            return stickyValue !== null
                ? JSON.parse(stickyValue)
                : defaultValue;
        });
        useEffect(() => {
            window.localStorage.setItem(key, JSON.stringify(value));
        }, [key, value]);
        return [value, setValue];
    }
    
    const [flaggedSubTasks, setFlaggedSubTasks] = useStickyState([], 'flaggedSubTasks')
    const [flaggedDoneSubTasks, setFlaggedDoneSubTasks] = useStickyState([], 'flaggedDoneSubTasks')
    const [onlyXDaysFromNow, setOnlyXDaysFromNow] = useStickyState(9999, 'onlyXDaysFromNow')
    const [swapState, setSwapState] = useStickyState(null, 'swapState') // to store the current state for automatic refreshing
    const [timeUp, setTimeUp] = useState(moment().toDate().toISOString()) // time which the page is first loaded

    const [showFlaggedTasksOnly, setShowFlaggedTasksOnly] = useStickyState(false, 'showFlaggedTasksOnly')

    const [autoTodo, setAutoTodo] = useStickyState(false, 'autoTodo')
    const [allowTimerAdjustForEndOfTask, setAllowTimerAdjustForEndOfTask] = useStickyState(true, 'allowTimerAdjustForEndOfTask')

    const [greenFlags, setGreenFlags] = useState(0)
    const [yellowFlags, setYellowFlags] = useState(0)
    const [redFlags, setRedFlags] = useState(0)

    // Update the greenFlags, yellowFlags, redFlags when subtasks are updated
    // Also update the flaggedSubTasks: remove flaggedSubTasks that are no longer in the subtasks
    useEffect(() => {
        let green = 0
        let yellow = 0
        let red = 0
        subtasks.forEach((subtask) => {
            // We do not add flags for future stamps
            if (subtask.hasOwnProperty('validFromDate'))
            {
                if (subtask.validFromDate.toDate() > moment(currentTime).toDate())
                {
                    return
                }
            }
            if (subtask.hasOwnProperty('subsubs'))
            {
                subtask.subsubs.forEach((subsub) => {
                    if (subsub.name.startsWith('***'))
                    {
                        red++
                    } else if (subsub.name.startsWith('**'))
                    {
                        yellow++
                    } else if (subsub.name.startsWith('*'))
                    {
                        green++
                    }
                })
            }
        })
        setGreenFlags(green)
        setYellowFlags(yellow)
        setRedFlags(red)

        // Also update the flaggedSubTasks: remove flaggedSubTasks that are no longer in the subtasks
        let newFlaggedSubTasks = []
        flaggedSubTasks.forEach((flaggedSubTask) => {
            let found = false
            subtasks.forEach((subtask) => {
                // Do not transfer flags from future stamps
                // console.log(subtask)
                if (subtask.hasOwnProperty('validFromDate'))
                {
                    if (subtask.validFromDate.toDate() > moment(currentTime).toDate())
                    {
                        console.log('Future stamp, do not transfer flags ' + subtask.sname)
                        return
                    }
                }

                if (subtask.hasOwnProperty('subsubs'))
                {
                    subtask.subsubs.forEach((subsub) => {
                        if (subtask.sname + subsub.name === flaggedSubTask)
                        {
                            found = true
                        }
                    })
                }
            })
            console.log('Will transfer flag ' + flaggedSubTask + ' ' + found + '')
            if (found === true)
            {
                newFlaggedSubTasks.push(flaggedSubTask)
            }
        })
        if (subtasks.length > 0)
            setFlaggedSubTasks(newFlaggedSubTasks)
    }, [subtasks])

    const overlayNotifications = useSelector(state => state.calendar.overlayNotifications)

    const [overlayNotification, setHomeScreenOverlayNotification] = useState({
        title: 'Test',
        message: 'Test content',
        show: false
    })

    useEffect(() => {
        // If there is a change in the list of overlay notifications, get the last one
        // console.log(overlayNotifications)
        if (overlayNotifications && overlayNotifications.length > 0)
        {
            let lastNotification = overlayNotifications[overlayNotifications.length - 1]
            // console.log('lastNotification', lastNotification)
            setHomeScreenOverlayNotification(lastNotification)
            if (lastNotification.show === true)
            {
                // In 30s, set overlayNotification.show to false 
                setTimeout(() => {
                    setHomeScreenOverlayNotification({...overlayNotification, show: false})
                }, 30000)
            }
        }
    }, [overlayNotifications])

    const [tasksToShowOnProcast, setTasksToShowOnProcast] = useState([])

    // Timer related states
    const [timerPercentage, setTimerPercentage] = useState(0)

    // Show important checklists
    const showCheckList = useSelector(state => state.calendar.showEmotionalEmergencyChecklist)

    // Show calendar adjusted timer caution
    const [showCalendarAdjustedTimerCaution, setShowCalendarAdjustedTimerCaution] = useState(false)
    const [allowCalendarAdjustedTimer, setAllowCalendarAdjustedTimer] = useStickyState(true, 'allowCalendarAdjustedTimer')

    useEffect(() => {
        if (timerActive == 1)
        {
            setTimerPercentage((timer1InitialValue - timer1)/timer1InitialValue * 100)
        } else {
            setTimerPercentage((defaultTimer2 - timer1)/defaultTimer2 * 100)
        }
    }, [timer1InitialValue, timer1, timerSignal, defaultTimer2])

    const [stampIdToShow, setStampIdToShow] = useStickyState(null, 'stampIdToShow')

    const [taskToGetFeedback, setTaskToGetFeedback] = useState(null)
    const [showFeedback, setShowFeedback] = useState(false)
    
    const [lastAdviceShown, setLastAdviceShown] = useStickyState(null, 'lastAdviceShown')
    const [showAdvice, setShowAdvice] = useState(false)

    const todosOrHabitsAvailable = useSelector(state => state.calendar.todosOrHabitsAvailable)

    const [showOnlyNonZerosAndCountUp, setShowOnlyNonZerosAndCountUp] = useStickyState(false, 'showOnlyNonZerosAndCountUp')

    const toggleShowOnlyNonZerosAndCountUp = () => {
        setShowOnlyNonZerosAndCountUp(x => !x)
    }

    const toggleShowOnlyNonZerosAndCountUpMemoized = useCallback(toggleShowOnlyNonZerosAndCountUp, [setShowOnlyNonZerosAndCountUp])

    // To indicate on the gauge how much time has been worked on from this point on
    const [sessionStartDateTime, setSessionStartDateTime] = useState(moment().toISOString())
    const [sessionStartTimerHistoryWorkMinutes, setSessionStartTimerHistoryWorkMinutes] = useState(0)
    
    const sessionLength = Number(window.localStorage.getItem('sessionLength'));

    const [timeUntilDisplay, setTimeUntilDisplay] = useState(null)

    // Countdown before timer starts

    async function loadData(swap = null)
    {
        if (swap == null)
        {
            swap = false
        }
        let subs = []
        // Load the tasks
        const querySnapshot = await getDocsx(queryx(collectionx(db, "subtasks")))
        if(querySnapshot.size == 0)
        {
            dispatch(setNetworkOk({networkOk: false}))
            dispatch(setCloudSyncing({cloudSyncing: false}))
            setNextSubTaskUpdate(moment().add(5, 'minute').toISOString())
            await updateRemainingFinishMemoized(Object.assign([], subtasks))
            return
        }

        // Count how many tasks that have validFrom later than now
        let count = 0
        querySnapshot.forEach((doc) => {
            let data = doc.data()
            if (data.hasOwnProperty('validFrom'))
            {
                if (moment(data.validFrom.toDate()).isBefore(moment()))
                {
                    count++
                }
            }
        })

        dispatch(setTaskCount({taskCount: count}))

        let mTasksToShowOnProcast = []
        querySnapshot.forEach((doc) => {
            let data = doc.data() 
            // Set the tasksToShowOnProcast
            let stampIdForDisplay = "DAILY"
            if (stampIdToShow != null)
            {
                stampIdForDisplay = stampIdToShow // the user has set the stampId to HOUSE or some other stamps
            } else {
                // stampIdToShow is null, we set it to the first stamp
                setStampIdToShow(stampIdForDisplay)
            }
            if (doc.id.indexOf(stampIdForDisplay)===-1)
            {
                // Filter out the subs that are not valid from now
                if (data.hasOwnProperty('subs'))
                {
                    // alert(JSON.stringify(data.subs))
                    let mSubs = _.cloneDeep(data.subs)
                    // Filter out mSubs where validFromDate is in the future
                    mSubs = mSubs.filter((x) => {
                        if (x.hasOwnProperty('validFromDate'))
                        {
                            if (moment(x.validFromDate.toDate()).isAfter(moment()))
                            {
                                return false
                            }
                        }
                        return true
                    }) // forEach

                    // Filter out subsubs where validFrom is in the future
                    mSubs.forEach((sub) => {
                        // Add stampId to sub
                        sub.stampId = data.id
                        if (sub.hasOwnProperty('subsubs'))
                        {
                            sub.subsubs = sub.subsubs.filter((x) => {
                                if (x.hasOwnProperty('validFrom') && x.validFrom != null)
                                {
                                    if (moment(x.validFrom.toDate()).isAfter(moment()))
                                    {
                                        return false
                                    }
                                }
                                return true
                            })
                        }
                    })
                    data.subs = mSubs
                    // console.log('Data is: ', data)
                }

                if (data.hasOwnProperty('validFrom'))
                {
                    if (moment(data.validFrom.toDate()).isBefore(moment()))
                    // if (true) // We show all tasks now, because this screen will indicate everything
                    {
                        // Add stampId to data
                        mTasksToShowOnProcast.push(data)
                        // console.log('mTasksToShowOnProcast **********')
                        // console.log(data)
                    } 
                } else
                {
                    mTasksToShowOnProcast.push(data) // comment out to show only tasks that are valid from now
                }
                // console.log('mTasksToShowOnProcast **********')
                // console.log(mTasksToShowOnProcast)
            }

            // Set the DAILY task
            let countUpRewards = 0 // This is the total reward for all countUp stamps
            let notYetActiveRewards = 0 // This is the total reward for all not yet active tasks

            if (doc.id.indexOf(stampIdForDisplay)>-1)
            {
                // This is the right task the user has chosen, we extract its serial number!
                setDailySn(doc.id)
                
                subs = data.subs
                
                subs.forEach((sub) => {
                    // If we encounter a countUp task, set its finish property to zero so that it will not be counted
                    if (sub.hasOwnProperty('countUp') && sub.countUp === 1)
                    {
                        // console.log('countUp task: ' + sub.sname)
                        countUpRewards += sub.finish
                        sub.finish = 0
                        sub.finishAfterReport = 0
                    }

                    // If we encounter a not yet active task, we add its finish to notYetActiveRewards
                    if (sub.hasOwnProperty('validFromDate'))
                    {
                        if (moment(sub.validFromDate.toDate()).isAfter(moment()))
                        {
                            // console.log('not yet active task: ' + sub.sname + sub.finish)
                            notYetActiveRewards += sub.finish
                        }
                    }

                    // Convert the finish to the correct "time" to display (the original field has been premultiplied with payrate)
                    if (sub.hasOwnProperty("finish"))
                    {
                        sub.finish = sub.finish / payrate * 0.5
                        sub.finishAfterReport = sub.finish // will be modified by updateRemainingFinish
                        // finish should be in "standard time unit, which is 25 minutes"
                        // the pay is given in this standard time unit. The timer we choose (e.g., 30 minutes)
                        // should be independent
                    }
                })
                // console.log('countUpRewards: ' + countUpRewards)
                // console.log('notYetActiveRewards: ' + notYetActiveRewards)
                // console.log('totalReward: ' + data.totalReward)
                // console.log('Setting totalHourOfTheStamp to: ' + (data.totalReward - countUpRewards - notYetActiveRewards) / payrate * 25 / 60)
                setTotalHourOfTheStamp((data.totalReward - countUpRewards - notYetActiveRewards) / payrate * 25 / 60)
            }
        })

        setTasksToShowOnProcast(mTasksToShowOnProcast)

        subs.forEach((sub) => {
            // Only add the initialFinish (to calculate the progress bar) if there isn't such a field
            if (!sub.hasOwnProperty('initialFinish'))
            {
                sub.initialFinish = sub.finish
            } else {
                sub.initialFinish = sub.initialFinish / payrate * 0.5
            }

            // Don't show not valid yet subsubs
            // Filter out subsubs where validFrom is in the future
            if (sub.hasOwnProperty('subsubs'))
            {
                sub.subsubs = sub.subsubs.filter((x) => {
                    if (x.hasOwnProperty('validFrom') && x.validFrom != null)
                    {
                        if (moment(x.validFrom).isAfter(moment()))
                        {
                            return false
                        }
                    }
                    return true
                })
            }
        })

        let tHistory = [] // to store swap timerHistory
        if (swapped) { // swapped is for url /homeswapped
            // this is to prevent infinite loop
            // The URL is homeswapped, so we need to load the data from swap storage
            tHistory = loadFromSwap(swap) // parameter to this function swap
            // console.log('tHistory: ', tHistory)
            if (tHistory)
            {
                await updateRemainingFinishMemoized(subs, tHistory)
            } else {
                await updateRemainingFinishMemoized(subs)
            }
        } else {
            await updateRemainingFinishMemoized(subs)
        }

        // console.log('Before sort: ', subs)

        // Sort the tasks, first by the time, and second by the name so that the order won't jump around
        subs.sort(function(s1, s2) {
            try
            {
                if (s2.finish < s1.finish)
                {
                    // console.log(s1.sname + s1.finish + ' -> ' + s2.sname + s2.finish)
                    return -1
                } else if (s1.finish < s2.finish) {
                    // console.log(s2.sname + s2.finish + ' -> ' + s1.sname + s1.finish)
                    return 1
                } else {
                    // s2.finish == s1.finish
                    // console.log('->', (s1.sname).localeCompare(s2.sname))
                    return (s1.sname).localeCompare(s2.sname);
                }
            } catch (err)
            {
                // Maybe the .finish field does not exist?
                return 1
            }
        })

        // console.log('After sort', subs)

        // Fetch balance to display on the profile pic
        fetchBalanceToStateMemoized()
        
        setSubtasks(Object.assign([], subs))
        // console.log("Load data called successfully")
        setNextSubTaskUpdate(moment().add(5, 'minute').toISOString())
        dispatch(setNetworkOk({networkOk: true}))
        dispatch(setCloudSyncing({cloudSyncing: false}))
    }

    const loadDataMemoized = useCallback(loadData, [dispatch, swapped, payrate, timerHistory])

    async function updateRemainingFinish(subs, tHistory = null)
    {
        // console.log(subs)
        // Load the reports to deduce the completed attempts
        const querySnapshot2 = await getDocsx(queryx(collectionx(db, "reports")))
        if (querySnapshot2.size > 0)
        { // with network connection
            querySnapshot2.forEach((doc) => {
                let data = doc.data() 
                let dataReport = data.report
                dataReport.forEach((entry) => {
                    subs.forEach((sub) => {
                        if (entry.sname == sub.sname)
                        {
                            let finishToDeduce = 0.5 * entry.time / (25 * 60 * 1000) // again, the deduction amount is in standard time unit
                            sub.finish -= finishToDeduce
                            sub.finishAfterReport = sub.finish
                        }
                    })
                })
                // setTimeReport(dataReport)    
            })
        } else {
            // without network connection
            // console.log('Does this fire?')
            subs.forEach((s) => {
                s.finish = s.finishAfterReport
            })
        }

        // console.log('After report: ', subs)

        // if tHistory is set, we use it to deduce the finish
        // otherwise, we use the timerHistory state
        if (tHistory)
        {
            tHistory.forEach((entry) => {
                subs.forEach((sub) => {
                    if (entry.subsub == sub.sname)
                    {
                        let finishToDeduce = 0.5 * entry.duration / (25 * 60 * 1000)
                        sub.finish -= finishToDeduce
                        // console.log('Deducing ' + finishToDeduce + ' from ' + sub.sname + ' because of timer history')
                    }
                })
            })
            // console.log(tHistory.length + ' entries in timerHistory')
        } else {
            timerHistory.forEach((entry) => {
                subs.forEach((sub) => {
                    if (entry.subsub == sub.sname)
                    {
                        let finishToDeduce = 0.5 * entry.duration / (25 * 60 * 1000)
                        sub.finish -= finishToDeduce
                        // console.log('Deducing ' + finishToDeduce + ' from ' + sub.sname + ' because of timer history')
                    }
                })
            })
            // console.log(timerHistory.length + ' entries in timerHistory')
        }

        

        


        const totalRemainingFinish = subs.filter((x) => {
            // if x has validFromDate property and it is in the future, we don't count it
            if (x.hasOwnProperty('validFromDate'))
            {
                if (moment(x.validFromDate.toDate()).isAfter(moment()))
                {
                    return false
                }
            }
            return true
        }).map((s) => s.finish).reduce(
            (previousValue, currentValue) => previousValue + currentValue * ((25 * 60 * 1000) / (30 * 60 * 1000)),
            0
          );
        setHourRemaining(totalRemainingFinish)
        setHourRemainingToDisplay(totalRemainingFinish)
    }

    const updateRemainingFinishMemoized = useCallback(updateRemainingFinish, [timerHistory, swapped])

    const clearTimer1Handle = () => {
        try {
            if (window.timer1Handle)
            {
                clearInterval(window.timer1Handle)
                window.timer1Handle = undefined
            }
        } catch (err)
        {
            // Do nothing
        }
    }

    const elementRef = useRef(null)

    const [ordoscopeVersion, setOrdoscopeVersion] = useState('')

    // Called once when page loads
    useEffect(async () => {
        // Set title page
        document.title = "Ordoscope"

        // Check if the lock screen is active
        if (locked)
        {
            window.location.href = '/'
        }

        if (firstTimeRender.current)
        {
            await loadData(true) // true means will perform swapping from local storage
            // console.log('First time render', firstTimeRender.current)
        }
        firstTimeRender.current = false
        // Start the timer so that remaining time until next events will update automatically
        let calendarTimer = calendarTick()
        fetchWeatherNew()
        // loadReportHistoryOfToday()
        window.timerNotBlocked = true
        const client = mqttConnect() // connect to the MQTT broker and receive the control signal
        
        // Load the defaultTimer values from persistedTimer values
        if (persistedTimer1 != defaultTimer1 && countUpMode === false)
        {
            setDuration1(persistedTimer1 / 60000)
        }

        if (persistedTimer2 != defaultTimer2 && countUpMode === false)
        {
            setDuration2(persistedTimer2 / 60000)
        }

        // Load profile pic from local storage
        const storedProfilePic = localStorage.getItem('profilePic');
        if (storedProfilePic) {
            setProfilePic(storedProfilePic);
        }

        // Setup the overflow handler to recalculate the width for shimmering effect for Todo and Habit components
        const handleOverflow = () => {
            try {
                let TopHabitElements = document.querySelectorAll('.TopHabit');
                TopHabitElements.forEach(element => {
                    element.classList.remove('TopHabit');
                    void element.offsetWidth; // Trigger a reflow
                    element.classList.add('TopHabit');
                });
            } catch (err)
            {
                // Do nothing
            }
            try {
                let TopTodoElements = document.querySelectorAll('.TitleTodo');
                TopTodoElements.forEach(element => {
                    element.classList.remove('TitleTodo');
                    void element.offsetWidth; // Trigger a reflow
                    element.classList.add('TitleTodo');
                });
            } catch (err)
            {
                // Do nothing
            }
        }

        window.handleOverflow = handleOverflow

        // Set the ordoscope version
        const urlParams = new URLSearchParams(window.location.search);
        const scope = urlParams.get('scope');
        
        if (scope === '3') {
            setOrdoscopeVersion('3');
        } else if (scope === '4') {
            setOrdoscopeVersion('4');
        } else {
            setOrdoscopeVersion('');
        }
        
        return () => {
            clearTimer1Handle()
            client.end()
            window.handleOverflow = undefined
            try {
                clearInterval(calendarTimer)
            } catch (err)
            {
                // Do nothing
            }

        }

    }, [])

    const selectSubSub = (subsub) => {
        let ss = Object.assign({}, subsub)
        if (ss.hasOwnProperty('expiryDate') && ss.expiryDate !== null)
        {
            ss.expiryDateStr = moment(ss.expiryDate.toDate()).toISOString()
        }
        setCurrentSubSub(ss)
        if (!subtasksHighlighted.includes(ss.sname))
        {
            setSubtasksHighlighted(ss.sname)
        }
        scrollYTaskPanel(-10000) // scroll to the top
    }

    const memoizedSelectSubSub = useCallback(selectSubSub, [])

    const adviceCheckBeforeTimerStart = () => {
        // if countdown to work is en cours, we cancel the countdown and start the real timer immediately
        let bypassAdvice = false // if true, we bypass the advice
        if (inCountdownToWorkMode || window.timerCountDownHandler != null)
        {
            cancelCountdownToWork()
            bypassAdvice = true
        }

        // check if a task has been selected
        if (currentSubSub.sname == null && timerSignal == 0)
        {
            alert('Please select a task first')
            return
        }

        // do not allow the timer to start if the task is a countUp task
        if (currentSubSub.countUp == 1)
        {
            // If the task is a countUp task, do not allow the timer to start
            alert('This is a count up task')
            return
        }

        // Check if we need to show the advisory signs or not
        let needToShowAdvice = checkShowAdvice()
        if (needToShowAdvice && !bypassAdvice)
        {
            setShowAdvice(true)
            // Close the advice after 10 seconds
            if (window.adviceTimer != null)
            {
                window.clearTimeout(window.adviceTimer)
                window.adviceTimer = undefined
            }
            window.adviceTimer = setTimeout(() => {
                setShowAdvice(false)
                if (timerSignal == 0)
                {
                    startTimerMemoized() // start the timer without showing the advisory signs
                }
            }, 10000)
            scrollY(-10000) // scroll to the top
        } else {
            startTimer() // start the timer without showing the advisory signs
        }
    }

    const startTimer = () => {
        if (timerSignal === 0)
        {
            setTimer1InitialValue(timer1)
            setTimer1InitialValue2(timer1)
            setTimerSignal(1)
            setShowAdvice(false)
        } else {
            return
        }
    }

    const startTimerMemoized = useCallback(startTimer, [timer1, timerSignal])

    useEffect(() => {
        // handling timerSignal change
        if (timerSignal == 1)
        {
            // Start a timer signal
            setTimerStartAt(moment().toISOString())
            setTimeUntilDisplay(computeUntilTimeValueWhileAwaringRoundingSeconds(moment(), timer1))
            // setChronometer1(0) // reset the chronometer if the timer is started
            window.timerNotBlocked = true;
        }
        if (timerSignal == 0)
        {
            // Pause a timer signal
            if (timerHandle !== null)
            {
                clearTimer1Handle()
                setTimerHandle(null)
                setTimer1Memo(timer1)
                setIdleSince(moment().toISOString())
            }
        }
        if (timerSignal == 3)
        {
            // Swap a timer signal
            // Pause a timer signal
            if (timerHandle !== null)
            {
                clearTimer1Handle()
                setTimerHandle(null)
            }
            if (timerActive == 1)
            {
                // swap to break
                setTimer1(defaultTimer2)
                setTimer2(defaultTimer1)
                setTimer1Memo(defaultTimer2)
                setTimerActive(2)
            } else {
                // swap to work

                if (allowCalendarAdjustedTimer || allowTimerAdjustForEndOfTask)
                {
                    // Calendar adjusted timer is allowed
                    // Similar code in the formatTimer function!

                    let minutesUntilEventStart = defaultTimer1 / 60000 + 1

                    // Make sure the timer does not exceed the remaining time to next event
                    if (neTimeDaily)
                    {
                        let eventStart = moment(neTimeDaily)
                        minutesUntilEventStart = moment.duration(eventStart.diff(moment())).asMinutes()
                        // console.log('1minutesUntilEventStart: ', eventStart.format('DD/MM/YYYY HH:mm:ss'))
                    }

                    if (neTimePersonal)
                    {
                        let eventStart2 = moment(neTimePersonal)
                        let minutesUntilEventStartPersonal = moment.duration(eventStart2.diff(moment())).asMinutes()
                        if (minutesUntilEventStartPersonal < minutesUntilEventStart)
                        {
                            minutesUntilEventStart = minutesUntilEventStartPersonal // take the smaller one (the closer event)
                        }
                    }

                    minutesUntilEventStart = Math.floor(minutesUntilEventStart - 1)
                    let minutesUntilStampEnds = defaultTimer1 / 6000
                    let decidedMinutesForNextRound = defaultTimer1 / 60000
                    
                    if (allowTimerAdjustForEndOfTask)
                    {
                        // See if the remaining time of the stamp is less than the default timer
                        if (remainingTimeOfTheStamp < defaultTimer1/60000)
                        {
                            minutesUntilStampEnds = remainingTimeOfTheStamp
                        }
                    }

                    if (allowCalendarAdjustedTimer)
                    {
                        if (minutesUntilEventStart > 1 && minutesUntilEventStart < defaultTimer1/60000)
                        {
                            decidedMinutesForNextRound = minutesUntilEventStart
                            // console.log('decidedMinutesForNextRound: ', decidedMinutesForNextRound)
                        }
                    }

                    if (allowTimerAdjustForEndOfTask)
                    {
                        if (minutesUntilStampEnds > 1 && minutesUntilStampEnds < defaultTimer1 / 60000)
                        {
                            if (minutesUntilStampEnds < decidedMinutesForNextRound)
                            {
                                decidedMinutesForNextRound = minutesUntilStampEnds
                                // console.log('decidedMinutesForNextRound: ', decidedMinutesForNextRound)
                            }
                        }
                    }

                    // console.log('decidedMinutesForNextRound: ', decidedMinutesForNextRound)
                    if (decidedMinutesForNextRound < defaultTimer1 / 60000)
                    {
                        // Set timer to the closest event
                        setTimer1(decidedMinutesForNextRound*60000)
                        setTimer1Memo(decidedMinutesForNextRound*60000)
                        setTimer2(defaultTimer1)
                        setTimerActive(1)
                        setTimer1InitialValue(decidedMinutesForNextRound*60000)
                        setTimer1InitialValue2(decidedMinutesForNextRound*60000)
                        setShowCalendarAdjustedTimerCaution(true)
                    } else {
                        setTimer1(defaultTimer1)
                        setTimer1Memo(defaultTimer1)
                        setTimer2(defaultTimer2)
                        setTimerActive(1)
                        setTimer1InitialValue(defaultTimer1)
                        setTimer1InitialValue2(defaultTimer1)
                        setShowCalendarAdjustedTimerCaution(false)
                    }
                } else {
                    // No calendar adjusted timer
                    setTimer1(defaultTimer1)
                    setTimer1Memo(defaultTimer1)
                    setTimer2(defaultTimer2)
                    setTimerActive(1)
                    setTimer1InitialValue(defaultTimer1)
                    setTimer1InitialValue2(defaultTimer1)
                    setShowCalendarAdjustedTimerCaution(false)
                }
                
            }
        }
        if (timerSignal == 4)
        {
            // Stop and Commit from a running timer
            // Pause a timer signal
            if (timerHandle !== null)
            {
                clearTimer1Handle()
                setTimerHandle(null)
            }
            // console.log('Stop: ', moment().toDate(), '<', moment(timerStartAt).toDate())
            setTimer1(defaultTimer1)
            setTimer1Memo(defaultTimer1)
            setTimer1InitialValue(defaultTimer1)
            setTimer1InitialValue2(defaultTimer1)
            setTimer2(defaultTimer2)
            // Commit
            setTimerHistory((tHistory) => {
                let tHistoryCopy = Object.assign([], tHistory)
                tHistoryCopy.push({
                    'subsub': currentSubSub.sname,
                    'done': moment().toDate(),
                    'duration': timer1InitialValue - timer1,
                    'id': Math.floor(Math.random() * 1000000000),
                    'tags': currentSubSub.tags ? currentSubSub.tags : "",
                    'allowFeedback': currentSubSub.allowFeedback > 0 ? currentSubSub.allowFeedback : 0,
                    'note': subtasksHighlighted != null ? subtasksHighlighted : "",
                    'bonusCoeff': currentSubSub.bonusCoeff ? currentSubSub.bonusCoeff : 1
                })
                // dispatch(setUnsavedChanges({unsavedChanges: true})) // this is already implemented in useEffect
                return tHistoryCopy
            })
            setTimerActive(1)
            setTimerSignal(0) // timer is stopped  
        }
        if (timerSignal == 6)
        {
            // Stop and Commit from a paused timer
            // Pause a timer signal
            if (timerHandle !== null)
            {
                clearTimer1Handle()
                setTimerHandle(null)
            }
            setTimer1(defaultTimer1)
            setTimer1Memo(defaultTimer1)
            setTimer1InitialValue(defaultTimer1)
            setTimer1InitialValue2(defaultTimer1)
            setTimer2(defaultTimer2)
            // Commit
            setTimerHistory((tHistory) => {
                let tHistoryCopy = Object.assign([], tHistory)
                tHistoryCopy.push({
                    'subsub': currentSubSub.sname,
                    'done': moment().toDate(),
                    'duration': timer1InitialValue - timer1,
                    'id': Math.floor(Math.random() * 1000000000),
                    'tags': currentSubSub.tags,
                    'note': subtasksHighlighted != null ? subtasksHighlighted : "",
                    'allowFeedback': currentSubSub.allowFeedback > 0 ? currentSubSub.allowFeedback : 0,
                    'bonusCoeff': currentSubSub.bonusCoeff ? currentSubSub.bonusCoeff : 1
                })
                // dispatch(setUnsavedChanges({unsavedChanges: true})) // this is already implemented in useEffect
                return tHistoryCopy
            })
            setTimerSignal(0) // timer is stopped  
        }
        if (timerSignal == 5)
        {
            
            // Stop and Discard
            // Pause a timer signal
            if (timerHandle !== null)
            {
                clearTimer1Handle()
                setTimerHandle(null)
            }
            // console.log('Stop: ', moment().toDate(), '<', moment(timerStartAt).toDate())    
            setTimer1(defaultTimer1)
            setTimer1Memo(defaultTimer1)
            setTimer1InitialValue(defaultTimer1)
            setTimer1InitialValue2(defaultTimer1)
            setTimer2(defaultTimer2)
            setTimerActive(1)
            setTimerSignal(0) // timer is stopped  
        
        }
    }, [timerSignal, defaultTimer1, defaultTimer2])

    useEffect(() => {
        // handling timerStartAt change
        if ((timerSignal == 1) && (timerHandle===null))
        {
            window.timer1Handle = setInterval(renderTimer, 1000)
            setTimerHandle(true)
        }
        return () => {
            if (window.timer1Handle)
            {
                clearInterval(window.timer1Handle)
                window.timer1Handle = undefined
            }
        }
    }, [timerStartAt])

    useEffect(() => {
        // handling timer1 change
        if (timerSignal == 3)
        {
            setTimerSignal(1) // the timerSignal == 3 handler will swap timer values
            window.timerNotBlocked = true;
            // which includes timer1 changes, which triggers this line of code to make the timer start again
        }
    }, [timer1])
    
    useEffect(() => {
        if (!firstTimeRender.current)
        {
            loadData()
            // console.log('First time render', firstTimeRender.current)
            // console.log('Calling loadData() from timerHistory useEffect')
        }
        if (timerHistory.length == 0)
        {
            dispatch(setUnsavedChanges({unsavedChanges: false}))
        } else {
            dispatch(setUnsavedChanges({unsavedChanges: true}))
        }

        // Update sessionStartTimerHistoryWorkMinutes
        try {
            let mTimerHistoryAfterSessionStartDateTime = timerHistory.filter((x) => {
                return moment(x.done).isAfter(moment(sessionStartDateTime))
            })
            setSessionStartTimerHistoryWorkMinutes(mTimerHistoryAfterSessionStartDateTime.map((x) => x.duration).reduce((a, b) => a + b, 0)/60000)
        } catch (err)
        {
            // Do nothing
            console.log(err)
        }
    }, [timerHistory, sessionStartDateTime])

    useEffect(() => {
        if (timerSignal == 1)
        {
            alert('Timer is running. Please stop the timer first.')
            return;
        }
        if (countUpMode){
            // in countUp mode, set timer1InitialValue to 180 minutes
            setTimer1InitialValue(180*60*1000)
            setTimer1(180*60*1000)
            setTimer1Memo(180*60*1000)
            setDefaultTimer1(180*60*1000)
            setTimer1InitialValue2(180*60*1000)
        } else {
            // in countDown mode, set timer1InitialValue to 0
            setTimer1InitialValue(25*60*1000)
            setTimer1(25*60*1000)
            setTimer1Memo(25*60*1000)
            setDefaultTimer1(25*60*1000)
            setTimer1InitialValue2(25*60*1000)
        }
    }, [countUpMode])

    const pauseTimer = () => {
        // Countdowntowork is running, cancel the countdown timer
        let countdownEnCours = cancelCountdownToWork()
        if (countdownEnCours)
        {
            return
        }

        if (timerSignal == 1)
        {
            // If the timer is running, simply pause it
            setTimerSignal(0)
        } else {
            if (timerSignal == 0 && currentSubSub.sname == null)
            {
                // If the timer is not running and no task is selected, do not allow the timer to start
                alert('Please select a task first')
                return
            }
            if (currentSubSub.countUp == 1)
            {
                // If the task is a countUp task, do not allow the timer to start
                alert('This is a count up task')
                return
            }
            // If the timer is not running, start it
            console.log(timer1)
            setTimer1InitialValue2(timer1)
            setTimerSignal(1)
        }
        
    }

    const stopAndCommitTimer = () => {
        // Countdowntowork is running, cancel the countdown timer
        let countdownEnCours = cancelCountdownToWork()
        if (countdownEnCours)
        {
            return
        }

        if (timerSignal == 3)
        {
            // Timer is paused
            setTimerSignal(6) // to commit the timer
        } else {
            if (timer1 == timer1InitialValue || currentSubSub.sname == null)
            {
                // Do not allow commit if the timer is not running or no task is selected
                return
            }
            setTimerSignal(4) 
        }
        setIdleSince(moment().toISOString())
    }

    const stopAndDiscardTimer = () => {
        // Countdowntowork is running, cancel the countdown timer
        let countdownEnCours = cancelCountdownToWork()
        if (countdownEnCours)
        {
            return
        }

        setTimerSignal(5)
        setIdleSince(moment().toISOString())
        // setTimerActive(1)
    }

    const renderTimer = () => {
        // console.log(moment().toDate(), '<', timerStartAt.toDate())
        // elapsed time since the button is pressed
        let msDiff = moment.duration(moment().diff(moment(timerStartAt))).asMilliseconds()
        let newRemainingTime = timer1InitialValue2 - msDiff
        if (timerActive == 2) {
            newRemainingTime = defaultTimer2 - msDiff
        }
        setTimer1((oldValue) => (newRemainingTime))
        if (newRemainingTime < 50)
        {
            console.log('Timer Block status: ', window.timerNotBlocked)
            if (window.timerNotBlocked)
            {
                window.timerNotBlocked = false
                let cycles = Math.abs(Math.floor(newRemainingTime / (defaultTimer1 + defaultTimer2))) + 1
                if (newRemainingTime < 0)
                {
                    cycles = Math.abs(Math.ceil(newRemainingTime / (defaultTimer1 + defaultTimer2))) + 1
                }
                
                console.log('Cycles to add: ', newRemainingTime)
                if (timerActive == 1)
                {
                    for(let i=0; i<cycles; i++)
                    {
                        //console.log(defaultTimer1)
                        //console.log(timer1)
                        setTimerHistory((tHistory) => {
                            let tHistoryCopy = Object.assign([], tHistory)
                            tHistoryCopy.push({
                                'subsub': currentSubSub.sname,
                                'done': moment().toDate(),
                                'duration': timer1InitialValue,
                                'id': Math.floor(Math.random() * 1000000000),
                                'tags': currentSubSub.tags,
                                'note': subtasksHighlighted != null ? subtasksHighlighted : "",
                                'allowFeedback': currentSubSub.allowFeedback > 0 ? currentSubSub.allowFeedback : 0,
                                'bonusCoeff': currentSubSub.bonusCoeff ? currentSubSub.bonusCoeff : 1
                            })
                            return tHistoryCopy
                        })
                    }
                    
                }
            } else {
                console.log('Thread execution blocked by window.timerNotBlocked = false')
            }
            
            setTimerSignal(3) // to swap the timers
        }
    }

    const formatTimer = (t) => {
        t = t + 350 // to make the timer more accurate
        let remainingTime = moment.utc(Math.abs(t))
        let remainingTimeStr
        let minRemaining
        if (!inCountdownToWorkMode && countUpMode)
        {
            // if the timer is counting up, show the elapsed time instead
            let elapsedTime = moment.utc(Math.abs(timer1InitialValue - t))
            if (showSeconds)
            {
                if (timer1InitialValue - t < 3600 * 1000)
                {
                    remainingTimeStr = elapsedTime.format('m:ss')
                } else {
                    remainingTimeStr = elapsedTime.format('H:mm:ss')
                }
            } else {
                remainingTimeStr = elapsedTime.format('H:mm[.]')
            }
            minRemaining = 30 // to determine whether a "*" should be added
        } else { // timer mode counting down
            if (showSeconds)
            {
                remainingTimeStr = remainingTime.format('m:ss')
            } else {
                remainingTimeStr = remainingTime.format('m[.]')
            }
            minRemaining = Number((remainingTime).format('m'))
            // return remainingTimeStr + ''
        }

        // -------------------------------
        // Similar code in timerSignal == 3!!!
        // -------------------------------
        if (neTimeDaily)
        {
            let eventStart = moment(neTimeDaily)
            let minutesUntilEventStart = moment.duration(eventStart.diff(moment())).asMinutes()
            // console.log('1minutesUntilEventStart: ', eventStart.format('DD/MM/YYYY HH:mm:ss'))
            
            if ((minutesUntilEventStart) > 0 && (minRemaining > minutesUntilEventStart))
            {
                return remainingTimeStr + '*'
            }
        }

        if (neTimePersonal)
        {
            let eventStart = moment(neTimePersonal)
            let minutesUntilEventStart = moment.duration(eventStart.diff(moment())).asMinutes()
            // console.log('2minutesUntilEventStart: ', minutesUntilEventStart)
            if ((minutesUntilEventStart) > 0 && (minRemaining > minutesUntilEventStart))
            {
                return remainingTimeStr + '*'
            }
        }

        return remainingTimeStr + ''
    }

    const formatTimerH = (t) => {
        // convert ms to H:mm
        let hour = Math.floor(Math.abs(t) / 3600000)
        let min = Math.round((Math.abs(t) - hour * 3600000) / 60000)
        if (min < 10)
        {
            min = '0' + min
        }
        if (min == 60)
        {
            hour += 1
            min = '00'
        }
        return hour + ':' + min
        // return moment.utc(Math.abs(t)).format('H:mm');
    }

    const deleteHistory = (item) => {
        if (timerSignal == 1)
        {
            return // do not allow deletion when the timer is running
        }
        console.log(timerHistory)
        let history = Object.assign([], timerHistory)
        history = history.filter((x) => x.id != item.id)
        setTimerHistory(history)
    }

    const deleteHistoryMemoized = useCallback(deleteHistory, [timerHistory, timerSignal])

    const finalize = async (showFeedbackAnyway = false) => {

        console.log('Begin finalize procedure...')

        const uniqueId = [...new Set(timerHistory.map(item => item.subsub))]
        const report = []
        if (uniqueId.length == 0)
        {
            alert('No task to save!')
            return
        }
        uniqueId.forEach((subsub) => {
            let timeAccumulated = 0
            let mTags = ''
            let allowFeedback = 0
            let bonusCoeff = 1
            timerHistory.forEach((th) => {
                if (th.subsub == subsub) {
                    timeAccumulated += th.duration
                    if (th.hasOwnProperty('tags') && th.tags !== null && th.tags !== undefined) {
                        mTags = th.tags
                    }
                    if (th.allowFeedback)
                    {
                        allowFeedback = th.allowFeedback
                    }
                    if (th.bonusCoeff)
                    {
                        bonusCoeff = th.bonusCoeff
                    }
                }
            })

            report.push({
                sname: subsub,
                stampId: stampIdToShow,
                time: timeAccumulated,
                tags: mTags,
                allowFeedback: allowFeedback,
                bonusCoeff: bonusCoeff
            })
        })

        // console.log(report)

        // Handle feedback logic ====================================
        let taskIdsToQuery = []
        report.forEach((entry) => {
            // console.log(entry)
            // if the task has allowFeedback property, and the time spent on the task is more than the allowFeedback property (in minutes)
            if (entry.allowFeedback > 0 && (entry.time / (60 * 1000)) > entry.allowFeedback)
            {
                // console.log('Allow feedback for ' + entry.sname)
                taskIdsToQuery.push(entry.sname)
            }
        })

        // alert('taskIdsToQuery: ' + taskIdsToQuery)

        // Sort the report by time so that the task with the longest time will display the feedback (we only display one feedback no matter how many tasks)
        let sortedReport = report.sort((a, b) => a.time > b.time && -1 || 1) // sort the report by time (descending)

        // alert('sortedReport: ' + JSON.stringify(sortedReport))

        // Convert the taskIdsToQuery to comma separated string
        let taskIdsToRetrieveFeedback = []
        if (showFeedbackAnyway === true) // user pressed on the SNF button instead of SND, which shows the feedback interface anyway
        {
            taskIdsToRetrieveFeedback = _.cloneDeep(sortedReport)
        } else { // user pressed on the SND, which we will check if a report on this task has been submitted within 24 hours, to prevent excessive feedback querying
            if (taskIdsToQuery.length > 0)
            {
                // Now we will query the API to see if we need to display the feedback interface or not
                let taskIdsToQueryStr = taskIdsToQuery.join(',')
                let queryResult = await queryNeedToFeedback(taskIdsToQueryStr)
                // alert(queryResult)
                // alert(JSON.stringify(queryResult))
                if (queryResult)
                {
                    // alert(JSON.stringify(queryResult)) // for debugging
                    // We will read the queryResult to pick the task to be displayed to obtain feedback
                    sortedReport.forEach((entry) => {
                        let canSaveEntry = true
                        queryResult.forEach((qr) => {
                            if (qr.taskId == entry.sname)
                            {
                                if (qr.iCanSave == false)
                                {
                                    canSaveEntry = false
                                }
                            }
                        })
                        if (canSaveEntry)
                        {
                            taskIdsToRetrieveFeedback.push(entry)
                        }
                    })
                }
            }
        }

        if (taskIdsToRetrieveFeedback.length > 0)
        {
            setTaskToGetFeedback(taskIdsToRetrieveFeedback[0])
            setShowFeedback(true)
            // scroll to the top of the page
            scrollY(-10000)
        } else {
            // scroll to the top of the page
            scrollY(-10000)
        }
        // ==========================================================
        // End of handling feedback logic

        // Uploading this report on the database
        let reportId = moment().format("DD-MM-YYYY-HH-mm-ss")
        let finalReport = { date: moment().toDate(), report: report }
        // Todo: enable these lines
        await setDocx(docx(db, "reports", reportId), finalReport)
        await syncReportHistoryOfToDayNewServer()
        console.log('Finalize procedure completed')
    }

    const finalizeMemoized = useCallback(finalize, [timerHistory])

    /* // Not used anymore
    const loadReportHistoryOfToday = async () => {
        const todayId = moment().add(-2, 'hour').format("YYYY-MM-DD")
        let todayDocRef = doc(db, "timerHistory", todayId)
        const docSnap = await getDoc(todayDocRef)
        if (!docSnap.exists())
        {
            console.log("No timer history log for today!")
        } else {
            let dat = docSnap.data().report
            console.log(dat)
            console.log('**************')
            dat.forEach((entry) => {
                // if entry.done is string, convert it to date
                if (typeof entry.done === 'string' || entry.done instanceof String)
                {
                    entry.done = moment(entry.done).toDate()
                } else {
                    entry.done = moment(entry.done.toDate()).toDate()
                }
            })
            setRemoteTimerHistory(dat)
        }
    }

    const syncReportHistoryOfToday = async () => {
        const todayId = moment().add(-2, 'hour').format("YYYY-MM-DD")
        let todayDocRef = doc(db, 'timerHistory', todayId)
        console.log(remoteTimerHistory.concat(timerHistory))
        await setDoc(todayDocRef, {"report": remoteTimerHistory.concat(timerHistory)})
        setTimerHistory([])
        await loadReportHistoryOfToday()
    } */

    const syncReportHistoryOfToDayNewServer = async () => {
        try {
            // Perform a FETCH POST request to paymemobile.fr/saveTimerHistory with data field containing the timerHistory
            let timerHistoryToSubmit = []
            timerHistory.forEach((entry) => {
                timerHistoryToSubmit.push({
                    'datetime': moment(entry.done).toISOString(),
                    'taskId': entry.subsub,
                    'duration': entry.duration/60000,
                    'note': entry.note != null ? entry.note : "",
                    'bonusCoeff': entry.bonusCoeff ? entry.bonusCoeff : 1
                })
            })

            let objectToSubmit = {data: JSON.stringify(timerHistoryToSubmit)}

            // Prepare the body
            const formData = new URLSearchParams();
            for (const [key, value] of Object.entries(objectToSubmit)) {
                if (value !== null && value !== undefined)
                {
                    formData.append(key, value);
                } else {
                    formData.append(key, "");
                }
            }

            const response = await fetch('https://paymemobile.fr/saveTimerHistory?token='+token, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: formData.toString()
            })

            if (response.ok)
            {
                // The response is ok, we can clear the timerHistory
                setTimerHistory([])
                // await loadReportHistoryOfToday()
            } else {
                alert('Error while syncing timer history to the server1')
            }
        } catch (err)
        {
            alert('Error while syncing timer history to the server')
            console.log(err)
        }
    }

    const toggleCalendar = (i) => {
        setRightPanelMode(prevRightPanelMode => {
            if (prevRightPanelMode + i < 0)
            {
                return 2
            }
            return (prevRightPanelMode + i) % 3
        })
    }

    const toggleCalendarMemoized = useCallback(toggleCalendar, [rightPanelMode])
    
    const setCalendarId = (i) => {
        setRightPanelMode(i)
    }

    const setCalendarIdMemoized = useCallback(setCalendarId, [])

    const toggleCalendarTimerCalendar = () => {
        if (rightPanelMode == 0)
        {
            setRightPanelMode(1)
        } else {
            setRightPanelMode(0)
        }
    }

    const toggleCalendarTimerCalendarMemoized = useCallback(toggleCalendarTimerCalendar, [rightPanelMode])

    let calendarTimer

    const calendarTick = () => {
        calendarTimer = !calendarTimer && setInterval(() => {
            setCurrentTime((prevTime) => moment().toISOString())
            dispatch(setGlobalClock(moment().toDate().toISOString())) // global tick all the components
        }, 1000)

        return calendarTimer
    }

    const addToTimer = (min) => {
        setTimer1(timer1Memo + min * 60 * 1000)
        setTimer1Memo(timer1Memo + min * 60 * 1000)
    }

    const addToTimerMemoized = useCallback(addToTimer, [timer1Memo])

    const scrollY = (y) => {
        window.scrollBy(0,y)
    }

    const scrollYTaskPanel = (y) => {
        let taskPanel = document.getElementById('taskPanel')
        if (taskPanel != null)
        {
            taskPanel.scrollBy(0,y)
        }
    }

    const sortTasksByActiveStatus = (tasks) => {
        let tasksExtended = Object.assign([], tasks)
        tasksExtended.forEach((te) => {
            if (te.sname == currentSubSub.sname)
            {
                te.priority = 100
            } else {
                te.priority = 0
            }
        })
        return tasksExtended.sort((a, b) => a.priority > b.priority && -1 || 1)
    }

    const hideActiveTaskFromList = (tasks) => {
        let tasksExtended = Object.assign([], tasks)
        return tasksExtended.filter((t) => {
            if (t.sname == currentSubSub.sname)
            {
                return false
            } else {
                return true
            }
        })
    }

    const getCurrentSubSubFromList = (tasks) => {
        let s = tasks.filter((t) => t.sname == currentSubSub.sname)
        if (s.length > 0)
        {
            return s[0]
        } else {
            return null
        }
    }

    useEffect(() => {
        // Automatically sync currentSubSub (to display as active task on the right hand side panel)
        // whenever subtasks change
        let subsub = Object.assign({}, getCurrentSubSubFromList(subtasks))
        if (subsub.sname)
        {
            selectSubSub(subsub)
            console.log('currentSubSub set')
            // console.log(subsub)
        }
    }, [subtasks, hourRemaining])

    /* const fetchWeather = async () => {
        console.log("Getting weather data...")
        try {
            const lat = localStorage.getItem('lat')
            const lon = localStorage.getItem('lon')
            const url = "https://api.openweathermap.org/data/2.5/onecall?lat=" + lat + "&lon=" + lon + "&exclude=minutely,hourly,daily,alerts&appid=bc45efa5dd213d806ed4caa9b5e61ccc"
            const response = await fetch(url)
            const json = await response.json()
            // console.log(json.current.weather.description)
            setWeather({
                temp: (json.current.temp - 273.15).toFixed(1),
                humidity: json.current.humidity,
                description: '',
                uvIndex: json.current.uvi,
                wind: json.current.wind_speed,
                sunrise: moment.unix(json.current.sunrise).format('HH:mm'),
                sunset: moment.unix(json.current.sunset).format('HH:mm'),
            })
            setNextWeatherUpdate(moment().add(10, 'minute').toISOString())

        } catch (exception)
        {
            console.log(exception)
            setNextWeatherUpdate(moment().add(10, 'minute').toISOString())
        }
    } */

    const fetchWeatherNew = async () => {
        try {
            const lat = localStorage.getItem('lat')
            const lon = localStorage.getItem('lon')
            const latlon = lat + ',' + lon
            const result = await fetch(`https://api.weatherapi.com/v1/current.json?key=ee5ebbc30f9048b8a2d53211241904&q=${latlon}&aqi=no`)
            const json = await result.json()
            const weatherObj = {
                temp: json.current.temp_c,
                humidity: json.current.humidity,
                description: json.current.condition.text,
                uvIndex: json.current.uv,
                wind: json.current.wind_kph,
                precipitation: json.current.precip_mm,
                cloud: json.current.cloud,
                icon: json.current.condition.icon,
                feelslike: json.current.feelslike_c,
            }
            setWeather(weatherObj)
            setNextWeatherUpdate(moment().add(10, 'minute').toISOString())
        } catch (exception)
        {
            console.log(exception)
            setNextWeatherUpdate(moment().add(10, 'minute').toISOString())
        }
    }

    const swapAndRedirect = () => {
        saveToSwap()
        if (swapped)
        {
            window.location.reload()
        } else {
            if (showSeconds){
                window.location.href = '/homeswapped'
            } else {
                window.location.href = '/homelegacyswapped'
            }
        }
    }

    useEffect(() => {
        if (moment(currentTime) > moment(nextWeatherUpdate))
        {
            fetchWeatherNew()
        }
        if (moment(currentTime) > moment(nextSubTaskUpdate))
        {
            loadData()
            setNextSubTaskUpdate(moment().add(5, 'minute').toISOString())
        }
        if (moment(currentTime) > moment(timeUp).add(2, 'hour'))
        {
            dispatch(setRefreshAdvised({refreshAdvised: true}))
            // If the page has been left open for more than 2 hours, we refresh the page
            setTimeUp(moment().add(20, 'second').toDate().toISOString()) // prevent happening again
            let minutesSinceIdle = moment.duration(moment().diff(moment(idleSince))).asMinutes()
            // console.log('Minutes since idle: ', minutesSinceIdle)
            if ((timerSignal == 0) && (timer1 == defaultTimer1) && (minutesSinceIdle > 2) && (!showFeedback))
            {
                swapAndRedirect()
            } else {
                setTimeUp(moment().add(5, 'minute').add(-2, 'hour').toDate().toISOString())
            }
        }
    }, [currentTime, nextWeatherUpdate, nextSubTaskUpdate, timerSignal, showFeedback])

    useEffect(() => {
        if (timerSignal == 0)
        {
            // If the timer is currently paused, we run the chronometer
            let minutesSinceIdle = moment.duration(moment().diff(moment(idleSince))).asMinutes()
            // console.log('Minutes since idle: ', minutesSinceIdle)
            // Show procast screen if the user has been idle for more than 30 minutes
            if (minutesSinceIdle > 30 && minutesSinceIdle < 30.03 && autoTodo && rightPanelMode!=1)
            {
                // If idle time is larger than 30 minutes, show a tip to avoid procastination
                if (allTodos.length > 0)
                {
                    setShowProcastTips(true)
                }
                // Set session start to the current time (for the yellow gauge)
                setSessionStartDateTime(moment().toDate().toISOString())
            }

            
            // setHourRemainingToDisplay(hourRemaining)
        }
        // Set the remaining time
        if (timerActive == 1)
        {
            setHourRemainingToDisplay((hr) => (hourRemaining - (timer1InitialValue - timer1)/(3600000)))
        }
    }, [currentTime]) // useEffect dependency is not the same as useMemo dependency (the latter is similar to useInterval)
    
    const showProcastScreen = () => {
        if (allTodos.length > 0)
        {
            setShowProcastTips(true)
            try {
                scrollY(-10000)
            } catch (e)
            {
                console.log(e)
            }
        }
    }

    const showProcastScreenMemoized = useCallback(showProcastScreen, [allTodos])

    const setDuration1 = (min) => {
        if (timerSignal != 0)
        {
            return
        }
        console.log("Set Duration 1")
        setDefaultTimer1(min * 60 * 1000)
        setTimer1InitialValue(min * 60 * 1000)
        setTimer1InitialValue2(min * 60 * 1000)
        setTimer1(min * 60 * 1000)
        setTimer1Memo(min * 60 * 1000)
        setPersistedTimer1(min * 60 * 1000)
    }

    const setDuration2 = (min) => {
        if (timerSignal != 0)
        {
            return
        }
        setDefaultTimer2(min * 60 * 1000)
        setTimer2(min * 60 * 1000)
        setPersistedTimer2(min * 60 * 1000)
    }

    useEffect(() => {
        if (!firstTimeRender.current)
        {
            loadData()
            console.log('From defaultTimer1')
            console.log('First time render', firstTimeRender.current)
        }
    }, [defaultTimer1])

    useEffect(() => {
        try{
            // let minuteStampRem = Number(((timerActive == 1)) ? (currentSubSub.finish * 2 * (25 * 60 * 1000) / 60000 - (timer1InitialValue - timer1)/60000).toFixed(0) : (currentSubSub.finish * 2 * (25 * 60 * 1000) / 60000).toFixed(0))
            let minuteStampRem = (timerActive == 1) ? (currentSubSub.finish * 2 * (25 * 60 * 1000) / 60000 - (timer1InitialValue - timer1)/60000) : (currentSubSub.finish * 2 * (25 * 60 * 1000) / 60000)
            let minuteCounted = currentSubSub.initialFinish * 2 * (25 * 60 * 1000) / 60000 - minuteStampRem 
            // console.log(currentSubSub.finish * 2 * (25 * 60 * 1000) / 60000, minuteStampRem, minuteCounted)
            // if minuteStampRem is NaN
            if (isNaN(minuteStampRem))
            {
                minuteStampRem = 0
                minuteCountedOfTheStamp = 0
            }
            setRemainingTimeOfTheStamp(minuteStampRem)
            setMinuteCountedOfTheStamp(minuteCounted)

            // console.log('minuteStampRem: ', minuteStampRem)
            // console.log('minuteCounted: ', minuteCounted)
        } catch (e)
        {
            setRemainingTimeOfTheStamp(0)
            setMinuteCountedOfTheStamp(0)
        }
    }, [timer1, timer1InitialValue, currentSubSub, subtasks])

    const flagSubTask = () => (sname, subtaskName) => {
        let fs = Object.assign([], flaggedSubTasks)
        let fds = Object.assign([], flaggedDoneSubTasks)
        if(flaggedSubTasks.indexOf(sname + subtaskName)==-1)
        {
            fs.push(sname + subtaskName)
            setFlaggedSubTasks(fs)
        } else {
            // If already flagged, another click will put it as "done"
            if (flaggedDoneSubTasks.indexOf(sname + subtaskName) == -1)
            {
                fds.push(sname + subtaskName)
                setFlaggedDoneSubTasks(fds)
            } else {
                // If already flagged and marked as done, another click will remove it from the list
                setFlaggedSubTasks(fs.filter(x => x !== (sname + subtaskName)))
                setFlaggedDoneSubTasks(fds.filter(x => x !== (sname + subtaskName)))
            }
        }
    }

    const flagAllSubTasks = (sname, ssnames) => {
        let fs = _.cloneDeep(flaggedSubTasks)
        let fds = _.cloneDeep(flaggedDoneSubTasks)
        if (ssnames == null || ssnames == undefined)
        {
            return
        }
        // Get the first subsub name to test whether it is already flagged
        let ssname0 = ssnames[0]
        if (flaggedSubTasks.indexOf(sname + ssname0) != -1)
        {
            // If the first subsub is already flagged, remove all flagged subtasks
            ssnames.forEach((ssname) => {
                fs = fs.filter(x => x !== (sname + ssname))
                fds = fds.filter(x => x !== (sname + ssname))
            })
            setFlaggedSubTasks(fs)
            setFlaggedDoneSubTasks(fds)
            return
        }

        // Else, add all subtasks to flaggedSubTasks and remove them from flaggedDoneSubTasks
        ssnames.forEach((ssname) => {
            if (flaggedSubTasks.indexOf(sname + ssname)==-1)
            {
                fs.push(sname + ssname)
            }
            if (flaggedDoneSubTasks.indexOf(sname + ssname)==-1)
            {
                // remove from flaggedDoneSubTasks
                fds = fds.filter(x => x !== (sname + ssname))
            }
        })
        setFlaggedSubTasks(fs)
        setFlaggedDoneSubTasks(fds)
    }

    const memoizedFlagSubTask = useCallback(flagSubTask(), [flaggedSubTasks, flaggedDoneSubTasks])
    const memoizedFlagAllSubTasks = useCallback(flagAllSubTasks, [flaggedSubTasks, flaggedDoneSubTasks])

    const markAllFlagsOfCurrentSubSubAsDone = () => {
        let fs = Object.assign([], flaggedSubTasks)
        let fds = Object.assign([], flaggedDoneSubTasks)
        if (currentSubSub == null || currentSubSub == undefined)
        {
            return
        }
        currentSubSub.subsubs.forEach((subsub) => {
            if (flaggedSubTasks.indexOf(currentSubSub.sname + subsub.name) != -1)
            {
                if (flaggedDoneSubTasks.indexOf(currentSubSub.sname + subsub.name) == -1)
                {
                    fds.push(currentSubSub.sname + subsub.name)
                }
            }
        })

        setFlaggedDoneSubTasks(fds)
    }

    const markAllFlagsOfCurrentSubSubAsDoneMemoized = useCallback(markAllFlagsOfCurrentSubSubAsDone, [flaggedSubTasks, flaggedDoneSubTasks, currentSubSub])


    const revertDoneFlagsBackToFlagged = () => {
        setFlaggedDoneSubTasks([])
    }

    const revertDoneFlagsBackToFlaggedMemoized = useCallback(revertDoneFlagsBackToFlagged, [flaggedSubTasks, flaggedDoneSubTasks, currentSubSub])

    const sortSubSubsWithFlaggedSubSubsFirst = (subsubs) => {
        let flaggedSubSubs = []
        let nonFlaggedSubSubs = []
        subsubs.forEach((subsub) => {
            if (currentSubSub.sname + '/' + subsub.name == subtasksHighlighted)
            {
                flaggedSubSubs.push(subsub)
                return
            } else {
                nonFlaggedSubSubs.push(subsub)
            }
        })
        return flaggedSubSubs.concat(nonFlaggedSubSubs)
    }

    const sortSubSubsWithFlaggedSubSubsFirstMemoized = useCallback(sortSubSubsWithFlaggedSubSubsFirst, [subtasksHighlighted])


    const flaggedSubTasksContainsSname = (sname) => {
        const flaggedNotDoneSubTasks = flaggedSubTasks.filter((x) => {
            return flaggedDoneSubTasks.indexOf(x) == -1
        })
        let result = false
        flaggedNotDoneSubTasks.forEach((x) => {
            if (x.indexOf(sname) != -1)
            {
                result = true
            }
        })
        return result
    }

    const memoizedFlaggedSubTasksContainsSname = useCallback(flaggedSubTasksContainsSname, [flaggedSubTasks, flaggedDoneSubTasks])

    const getProgressBarColor = (percentage) => {
        if (percentage > 66.7)
        {
            return 'progress-green'
        } else if (percentage > 33.3)
        {
            return 'progress-yellow'
        } else {
            return 'progress-red'
        }
    }

    const getTimerColor = (percentage) => {
        if (countUpMode)
        {
            return 'timer-green'
        }

        if (percentage > 66.7)
        {
            return 'timer-green'
        } else if (percentage > 33.3)
        {
            return 'timer-yellow'
        } else {
            return 'timer-red'
        }
    }

    const allNotis = useSelector(state => state.calendar.notifications)

    const saveToSwap = () => {
        let swap = {}
        swap['timerHistory'] = _.cloneDeep(timerHistory)
        swap['currentSubSub'] = _.cloneDeep(currentSubSub)
        swap['defaultTimer1'] = defaultTimer1
        swap['defaultTimer2'] = defaultTimer2
        swap['subtasksHighlighted'] = subtasksHighlighted
        swap['showProcastTips'] = showProcastTips
        // swap['chronometer1'] = chronometer1
        swap['idleSince'] = idleSince
        swap['countUpMode'] = countUpMode
        swap['notifications'] = _.cloneDeep(allNotis)
        swap['showTimeInsteadOfTimer'] = showTimeInsteadOfTimer
        swap['rightPanelMode'] = rightPanelMode
        swap['sessionStartDateTime'] = sessionStartDateTime
        swap['sessionStartTimerHistoryWorkMinutes'] = sessionStartTimerHistoryWorkMinutes
        setSwapState(swap)
        console.log('Saved to swap')
        console.log(swap)
    }

    const loadFromSwap = (swap) => {
        if (swapState == null || !swap)
        {
            return
        }
        console.log('Loaded from swap')
        console.log(swapState)
        let tHistory = _.cloneDeep(swapState.timerHistory)
        setTimerHistory(tHistory)
        // Find the subsub that matches the currentSubSub
        setCurrentSubSub(_.cloneDeep(swapState.currentSubSub))
        setDefaultTimer1(swapState.defaultTimer1)
        setDefaultTimer2(swapState.defaultTimer2)

        setSubtasksHighlighted(swapState.subtasksHighlighted)
        setShowProcastTips(swapState.showProcastTips)
        // setChronometer1(swapState.chronometer1)
        setIdleSince(swapState.idleSince)
        setCountUpMode(swapState.countUpMode)
        setShowTimeInsteadOfTimer(swapState.showTimeInsteadOfTimer)
        dispatch(setNotifications({notifications: _.cloneDeep(swapState.notifications)}))
        if (swapState.rightPanelMode != null)
        {
            setRightPanelMode(swapState.rightPanelMode)
        }
        if (swapState.sessionStartDateTime != null)
        {
            setSessionStartDateTime(swapState.sessionStartDateTime)
        }
        if (swapState.sessionStartTimerHistoryWorkMinutes != null)
        {
            setSessionStartTimerHistoryWorkMinutes(swapState.sessionStartTimerHistoryWorkMinutes)
        }
        return tHistory // to compute remaining finish in loadData()
    }

    const deactivateCurrentSubSub = () => {
        setCurrentSubSub({})
        setSubtasksHighlighted('')
    }

    const setHighlightedSubSubHelper = (val) => {
        if (val != subtasksHighlighted)
        {
            setSubtasksHighlighted(val)
        } else {
            try {
            setSubtasksHighlighted(currentSubSub.sname)
            } catch (e)
            {
                setSubtasksHighlighted('')
            }
        }
        scrollYTaskPanel(-10000) // scroll to the top
    }

    const deactivateCurrentSubSubMemoized = useCallback(deactivateCurrentSubSub, [])
    const setHighlightedSubSubHelperMemoized = useCallback(setHighlightedSubSubHelper, [subtasksHighlighted])


    const makeTimer1RemainingMinutes = () => {
        setTimer1(remainingTimeOfTheStamp * 60000)
    }

    const makeTimer1RemainingMinutesMemoized = useCallback(makeTimer1RemainingMinutes, [remainingTimeOfTheStamp])

    const computeUntilTimeValueWhileAwaringRoundingSeconds = (currentTimeX, timer1X) => {
        let provisionaryUntilTime = moment(currentTimeX).add(timer1X, 'ms')
        if (provisionaryUntilTime.seconds() == 0)
        {
            provisionaryUntilTime = provisionaryUntilTime.set('second', 59)
            provisionaryUntilTime = provisionaryUntilTime.set('minute', provisionaryUntilTime.minutes() - 1)
        }
        return provisionaryUntilTime.format('H:mm')
    }

    const computeUntilTimeValueWhileAwaringRoundingSecondsMemoized = useCallback(computeUntilTimeValueWhileAwaringRoundingSeconds, [])

    const toggleAutoTodo = () => {
        setAutoTodo(!autoTodo)
    }

    const toggleAutoTodoMemoized = useCallback(toggleAutoTodo, [autoTodo])

    const toggleTimerAdjustForEndOfTask = () => {
        setAllowTimerAdjustForEndOfTask(!allowTimerAdjustForEndOfTask)
    }

    const toggleTimerAdjustForEndOfTaskMemoized = useCallback(toggleTimerAdjustForEndOfTask, [allowTimerAdjustForEndOfTask])

    const hideFeedbackUX = () => {
        setShowFeedback(false)
        scrollY(-10000)
    }

    const hideFeedbackUXMemoized = useCallback(hideFeedbackUX, [])

    const checkShowAdvice = () => {
        if (lastAdviceShown == null)
        {
            setLastAdviceShown(moment().toISOString())
            return true 
        } else {
            // If the last advice was shown more than 2 hours ago, we show the advice
            if (moment().diff(moment(lastAdviceShown), 'hours') > 2)
            {
                setLastAdviceShown(moment().toISOString())
                return true
            } else {
                return false
            }
        }
    }

    const onCloseEmergencyChecklist = () => {
        dispatch(setShowEmotionalEmergencyChecklist({showEmotionalEmergencyChecklist: !showCheckList}))
    }

    const onCloseEmergencyChecklistMemoized = useCallback(onCloseEmergencyChecklist, [showCheckList])

    const switchToAllTasksRightPanel = () => {
        if (rightPanelMode != 2)
        {
            setRightPanelMode(2)
        } else {
            setRightPanelMode(0)
        }
    }

    const switchToAllTasksRightPanelMemoized = useCallback(switchToAllTasksRightPanel, [rightPanelMode])

    // Countdown until the work starts, for example, when the user is procrastinating
    const [showProcastinateCountdown, setShowProcastinateCountdown] = useStickyState(true, 'showProcastinateCountdown')
    const toggleProcastinateCountdown = () => {
        setShowProcastinateCountdown(!showProcastinateCountdown)
        scrollY(-10000) // scroll to the top
    }

    const toggleProcastinateCountdownMemoized = useCallback(toggleProcastinateCountdown, [showProcastinateCountdown])
    
    const [inCountdownToWorkMode, setInCountdownToWorkMode] = useState(false)
    const [timerCountDown, setTimerCountDown] = useState(0)
    const [minutesSetForCountdown, setMinutesSetForCountdown] = useState(0)

    const [profilePic, setProfilePic] = useState(null)

    const cancelCountdownToWork = () => {
        // Cancel the countdown timer if it is running
        if (window.timerCountDownHandler != null)
        {
            clearInterval(window.timerCountDownHandler)
            window.timerCountDownHandler = undefined // == null also returns true
            setInCountdownToWorkMode(false)
            setMinutesSetForCountdown(0)
            return true 
        } else {
            return false
        }
    }

    const startCountdownToWork = (minutesToCountdown) => {
        if (timerSignal != 0 || window.timer1Handle != null)
        {
            // If the timer is running, we do not allow the countdown to start
            setMinutesSetForCountdown(0)
            return 
        }

        if (currentSubSub.sname == null && timerSignal == 0)
        {
            alert('Please select a task first')
            setMinutesSetForCountdown(0)
            return
        }

        // Clear the timerCountDown if it is already running
        if (window.timerCountDownHandler != null)
        {
            clearInterval(window.timerCountDownHandler)
            window.timerCountDownHandler = undefined // == null also returns true
            setInCountdownToWorkMode(false)
            setMinutesSetForCountdown(0)
            return
        }

        setMinutesSetForCountdown(minutesToCountdown)
        scrollY(-10000) // scroll to the top

        let countdownEndsAt = moment().add(minutesToCountdown, 'minutes')
        setInCountdownToWorkMode(true)
        window.timerCountDownHandler = setInterval(() => {
            if (moment() > countdownEndsAt)
            {
                // when timer ends, we clear the interval
                clearInterval(window.timerCountDownHandler)
                window.timerCountDownHandler = undefined
                // setShowProcastinateCountdown(false)
                setInCountdownToWorkMode(false)
                setMinutesSetForCountdown(0)
                startTimerMemoized()
                return
            }
            // get the difference between the current time and the countdownEndsAt in milliseconds
            let diff = moment(countdownEndsAt).diff(moment(), 'milliseconds')
            // console.log('Counting down: diff = ' + diff)
            setTimerCountDown(diff)
        }, 1000)
    }

    const getBackgroundClassLogic = () => {
        if ((timerActive == 2 && timerSignal == 1) || (inCountdownToWorkMode))
        {
            return "WhiteTheme"
        } else {
            if (imminentCalendarBar == 'imminent')
            {
                return 'AppRootYellow'
            } else if (imminentCalendarBar == 'active')
            {
                return 'AppRootRed'
            } else 
            {
                return ''
            }
            // imminentCalendarBar == 'imminent' ? 'AppRootYellow' : (imminentCalendarBar == 'active' ? 'AppRootRed' : ''
        }
    }

    const startCountdownToWorkMemoized = useCallback(startCountdownToWork, [timerSignal, currentSubSub])
    const [balance, setBalance] = useState("0")


    const fetchBalanceToState = async () => {
        const balance = await fetchBalance()
        setBalance(balance)
    }

    const fetchBalanceToStateMemoized = useCallback(fetchBalanceToState, [])

    return(
        <div className={`AppRoot ${getBackgroundClassLogic()} `}>
        <div className={`AppRootInnerOverlay`}>
            
        { /* OVERLAY NOTIFICATION */}

        { showCheckList && <Checklist show={true} onClose={onCloseEmergencyChecklistMemoized}></Checklist> }

        { showAdvice && 
            <div className={`AppV AppOverlay`}>
                <Advice callback={startTimerMemoized} ></Advice>
            </div>
        }

        { showFeedback && <div className={`AppV AppOverlay`}>
            <Feedback taskId={taskToGetFeedback.hasOwnProperty('sname') ? taskToGetFeedback.sname : ""} duration={taskToGetFeedback.hasOwnProperty('time') ? taskToGetFeedback.time / 60000 : 0} cancelHandler={hideFeedbackUXMemoized} submitSuccessfulHandler={hideFeedbackUXMemoized} ></Feedback>
        </div>
        }

        { overlayNotification.show && <div className={`AppV AppOverlay`}>
            <div style={{flex: '1 0 auto', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                <div style={{display: 'inline-block'}}>
                    <div style={{color: 'cyan', fontWeight: 'bold', fontSize: 20, marginBottom: 12}}>{overlayNotification.title}</div>
                    <div style={{color: 'white', fontSize: 16, marginBottom: 12, justifyContent: 'center'}}>{overlayNotification.message}</div>
                    <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'row'}}>
                        <Button variant='primary' onClick={() => {setHomeScreenOverlayNotification({...overlayNotification, show: false})}}>I'm Ready</Button>
                    </div>
                    
                </div>
            </div>
        </div> }

        { showProcastTips && <div className={`AppV AppOverlay AppOverlayTodos`}>
            <div className="OverlayLeftColumn">
                <div>
                    <div className='Label' style={{ marginTop: 0, fontWeight: 'bold', fontSize: 16, marginBottom: 12}}>YOUR GOALS</div>
                        <div>
                            { /* Display todos starting with * */ }
                            <Ticker data = {allTodos}></Ticker>
                        </div>
                    <div className='Label' style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 12, marginTop: 12}}>ALSO ON YOUR SCHEDULE</div>
                            <TickerList data = {allTodos} nItems = {3} onlyXDaysFromNow={onlyXDaysFromNow}></TickerList>

                    {/* <ul>
                        {allTodos && allTodos.map((td) => {
                            if (!td.content.startsWith('*'))
                            {
                                let daysUntil = moment(td.deferUntil).diff(moment(), 'days')
                                return(<li>{td.content} {td.hasOwnProperty('deferUntil') ? ' (' + moment(td.deferUntil).format('D/MM') + ' | ' + daysUntil +  'd)' : ''}</li>)
                            }
                        })}
                    </ul> */}
                    <div className="ProcastButtons" style={{display: 'flex', flexDirection: 'row', marginTop: 16}}>
                        <Button variant='dark' size="sm" onClick={() => {setShowProcastTips(false)}} style={{marginRight: 2}}>OK</Button>
                        <Button variant={`${onlyXDaysFromNow == 9999 ? 'primary' : 'dark'}`}  onClick={() => {setOnlyXDaysFromNow(9999)}} size="sm">ALL</Button>
                        <Button variant={`${onlyXDaysFromNow == 3 ? 'primary' : 'dark'}`} onClick={() => {setOnlyXDaysFromNow(3)}} size="sm">3</Button>
                        <Button variant={`${onlyXDaysFromNow == 7 ? 'primary' : 'dark'}`} onClick={() => {setOnlyXDaysFromNow(7)}} size="sm">7</Button>
                        <Button variant={`${onlyXDaysFromNow == 14 ? 'primary' : 'dark'}`}  onClick={() => {setOnlyXDaysFromNow(14)}} size="sm">14</Button>
                        <Button variant={`${onlyXDaysFromNow == 31 ? 'primary' : 'dark'}`}  onClick={() => {setOnlyXDaysFromNow(31)}} size="sm">31</Button>
                    </div>
                </div>
            </div>


            <div className="OverlayRightColumn">
                <div>
                    <div className='Label' style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 12, marginTop: 0}}>UPCOMING</div>
                    <CalendarAnimatedList nItemsDisplay={2} maxCalendarItems={5} todayOnly={true} currentTime={moment(currentTime)}></CalendarAnimatedList>
                </div>
                <div>
                    <div className='Label' style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 12, marginTop: 0}}>OTHER TASKS</div>
                    <TickerTask tasksToShowOnProcast = {tasksToShowOnProcast} loadTaskHandler={loadDataMemoized}></TickerTask>
                </div>
            </div>

        </div> }
        
        <div className={`AppV MainUI `}>
    
            <div className="CalendarBar">
                <Calendar onToggleCalendar={toggleCalendarTimerCalendarMemoized}></Calendar>
            </div>
            
            <div className={`App`}>
                <div className="Pomodoro">


                    <div className={`MinHeightTimerLayout`}>
                        <div style={{display: displayTimerOptions ? 'block' : 'none'}}>
                            <div style={{display: 'flex', flexDirection: 'row', marginLeft: 8, marginRight: 8, marginTop: todosOrHabitsAvailable ? 5 : 0}} className="TodosAndHabits">
                                <Todos refreshFlag={todoRefreshFlag}></Todos>
                                <Habits></Habits>
                            </div>
                        </div>
                        
                        <Notifications showSeconds={showSeconds}></Notifications>
            
                        <div className="TimerCommandButtons" style={{marginTop: 0, marginLeft: 8, marginRight: 8, display: !showTimeInsteadOfTimer ? 'block' : 'none'}}>
                            <div className="TimerCommandButtonsRows TimerControlPrimaries">
                                <Button variant="success" onClick={adviceCheckBeforeTimerStart} ><div className="ButtonAlign"><BsPlayCircleFill className="ButtonGlyph"></BsPlayCircleFill><span>ST</span></div></Button>
                                <Button variant="warning" onClick={() => {pauseTimer()}}><div className="ButtonAlign"><BsPauseCircleFill className="ButtonGlyph"></BsPauseCircleFill><span>PS</span></div></Button>
                                <Button variant="primary" onClick={() => {stopAndCommitTimer()}}><div className="ButtonAlign"><BsCheckCircleFill className="ButtonGlyph"></BsCheckCircleFill><span>CM</span></div></Button>
                                <Button variant="danger" onClick={() => {stopAndDiscardTimer()}}><div className="ButtonAlign"><BsFillBackspaceFill className="ButtonGlyph"></BsFillBackspaceFill><span>DS</span></div></Button>
                            </div>
                            
                            <div className="WarningAreas">
                                {(!currentSubSub.sname) &&
                                <div className="NoTaskWarn">
                                    <div><AiFillWarning style={{marginRight: 5}}></AiFillWarning>No task</div>
                                </div>}
                                {showCalendarAdjustedTimerCaution && <div className="TimerAdjustedWarn" onClick={() => {setShowCalendarAdjustedTimerCaution(false)}}>
                                    <div><AiFillWarning style={{marginRight: 5}}></AiFillWarning>Timer adjusted</div>
                                </div>}
                            </div>
                        </div>

                        <div style={{flex: 1}}></div> {/* Cushioning space to align elements*/}
                        <div style={{display: !showTimeInsteadOfTimer ? 'block' : 'none'}}>
                            <div className="Timer">
                                <div className="timerStatusIconL">
                                    <div className={`${(timerSignal == 1) && (timerActive == 1) ? getTimerColor(timerPercentage) : ""}`}>
                                        {inCountdownToWorkMode && <FaRegHourglass></FaRegHourglass>}
                                        {!inCountdownToWorkMode && timerSignal !== 1 && <MdTimerOff></MdTimerOff>}
                                        {!inCountdownToWorkMode && timerSignal == 1 && timerActive == 1 && <FaWalking></FaWalking>}
                                        {!inCountdownToWorkMode && timerSignal == 1 && timerActive == 2 && <FaCoffee></FaCoffee>}
                                    </div>
                                </div>
                                <div className="NumericFields" style={{textAlign: 'left'}}>
                                    {inCountdownToWorkMode &&
                                        <div onClick={() => {setCalendarId(1); setShowTimeInsteadOfTimer(!showTimeInsteadOfTimer)}}>{formatTimer(timerCountDown)}</div>
                                    }
                                    {!inCountdownToWorkMode &&
                                        <div className={`${((timerSignal == 1) && (timerActive == 1)) ? getTimerColor(timerPercentage) : ""}`} onClick={() => {setCalendarId(1); setShowTimeInsteadOfTimer(!showTimeInsteadOfTimer)}}>{formatTimer(timer1)}{/* <span style={{fontSize: 14}}>MIN</span> */}</div>
                                    }
                                </div>
                                <div className="NumericFields" style={{textAlign: 'right'}}>
                                    <div className={`${hourRemainingToDisplay < 0 ? 'TitleRouge' : ''}`} onClick={() => {setShowElapsedTimeOfThisStamp(!showElapsedTimeOfThisStamp)}}>{showElapsedTimeOfThisStamp ? formatTimerH((totalHourOfTheStamp - hourRemainingToDisplay)*3600*1000) : formatTimerH(hourRemainingToDisplay*3600*1000)}</div>
                                </div>
                                <div className={`timerStatusIconR ${hourRemainingToDisplay < 0 ? 'TitleRouge' : ''}`}>
                                    <div>
                                        {showElapsedTimeOfThisStamp && <AiOutlineCaretUp></AiOutlineCaretUp>}
                                        {!showElapsedTimeOfThisStamp && <AiOutlineCaretDown></AiOutlineCaretDown>}
                                    </div>
                                </div>
                                
                            </div>
                            <div className="StandbyTimer" style={{display: (timerSignal == 1) ? "none" : "flex"}}>
                                <div style={{flexDirection: 'column'}}>
                                    <div style={{marginLeft: 12, display: 'flex', alignItems: 'center'}}><AiOutlineFlag style={{marginRight: 5}}></AiOutlineFlag> <span>{
                                            // Count number of flaggedSubtasks not in flaggedDoneSubtasks
                                            flaggedSubTasks.length - flaggedSubTasks.filter((x) => {
                                                if (flaggedDoneSubTasks.includes(x)) {
                                                    return false
                                                } else {
                                                    return true
                                                }
                                            }
                                            ).length
                                    } / {flaggedSubTasks.length}</span></div>
                                    <div style={{textAlign: 'left', paddingLeft: 12}}>
                                        <Chronometer idleSince={idleSince} currentTime={currentTime} setIdleSince={setIdleSince}></Chronometer>
                                        <TaskCount totalTaskCount={totalTaskCount} onClick={switchToAllTasksRightPanelMemoized} />
                                    </div>
                                </div>
                                
                                <div style={{flex: 1, paddingRight: 12, flexDirection: 'column', display: 'flex'}}>
                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                                        <div className="flagsColorWrapper" style={{display: 'flex', flexDirection: 'row'}}>
                                            <div className="timer-green"><AiOutlineFlag></AiOutlineFlag> {greenFlags}</div>
                                            <div className="timer-yellow" style={{marginLeft: 6}}><AiOutlineFlag></AiOutlineFlag> {yellowFlags}</div>
                                            <div className="flag-red" style={{marginLeft: 6}}><AiOutlineFlag></AiOutlineFlag> {redFlags}</div>
                                        </div>
                                    </div>


                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                                        <div style={{marginLeft: 6}} onClick={() => setShowFlaggedTasksOnly(!showFlaggedTasksOnly)}><BiTask></BiTask> {subtasks.filter((x) => {
                                            if (x.hasOwnProperty('validFromDate')) {
                                                if (moment(x.validFromDate.toDate()).isAfter(moment(currentTime))) {
                                                    return false // do not consider subtask if validFromDate is in the future
                                                }
                                            }
                                            if (x.finish > 0) {
                                                return true
                                            } else {
                                                return false
                                            }
                                        }).length}</div>
                                    </div>

                                    
                                </div>
                                
                            </div>
                            <div className="VisualTimerProgressBar" style={{display: (timerSignal == 1) ? "flex" : "none"}}>
                                <div style={{flex: 1, flexDirection: 'column'}}>
                                    {!countUpMode && <ProgressBar className={getProgressBarColor(timerPercentage)} now={timerPercentage} style={{marginBottom: 6}}></ProgressBar>}
                                    <div style={{flex: 1, paddingRight: 12, flexDirection: 'row', display: 'flex'}}>
                                        <div className="flagsColorWrapper" style={{display: 'flex', flexDirection: 'row'}}>
                                            <div className="timer-green"><AiOutlineFlag></AiOutlineFlag> {greenFlags}</div>
                                            <div className="timer-yellow" style={{marginLeft: 6}}><AiOutlineFlag></AiOutlineFlag> {yellowFlags}</div>
                                            <div className="flag-red" style={{marginLeft: 6}}><AiOutlineFlag></AiOutlineFlag> {redFlags}</div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{flex: 1, textAlign: 'right', flexDirection: 'column'}}>
                                    <div>{!countUpMode && <span className="workingHighlight">Until {timeUntilDisplay || "-"}</span>}</div>
                                    <div style={{flex: 1, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end'}}>
                                        <div style={{marginLeft: 6}}><AiOutlineFlag></AiOutlineFlag> {
                                        // Count number of flaggedSubtasks not in flaggedDoneSubtasks
                                        flaggedSubTasks.length - flaggedSubTasks.filter((x) => {
                                            if (flaggedDoneSubTasks.includes(x)) {
                                                return false
                                            } else {
                                                return true
                                            }
                                        }
                                        ).length
                                    } / {flaggedSubTasks.length}</div>
                                        <div style={{marginLeft: 6}}><BiTask></BiTask> {subtasks.filter((x) => {
                                        if (x.hasOwnProperty('validFromDate')) {
                                            if (moment(x.validFromDate.toDate()).isAfter(moment(currentTime))) {
                                                return false // do not consider subtask if validFromDate is in the future
                                            }
                                        }
                                        if (x.finish > 0) {
                                            return true
                                        } else {
                                            return false
                                        }
                                    }).length}</div>
                                    <TaskCount totalTaskCount={totalTaskCount} onClick={switchToAllTasksRightPanelMemoized} />
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        {showTimeInsteadOfTimer && <div className="ClockInHomeScreen" style={{display: showTimeInsteadOfTimer ? 'flex' : 'none'}} onClick={() => {setShowTimeInsteadOfTimer(!showTimeInsteadOfTimer); setCalendarId(0)}}>
                            {/* Show weather */}
                            <WeatherDashboard weather={weather}></WeatherDashboard>
                        </div> // end of showTimeInsteadOfTimer - showing the clock
                        } 
                        {/* <div style={{marginTop: 8}}>
                            <Notis></Notis>
                        </div> */}
                        <div style={{flex: 1}}></div> {/* Cushioning space to align elements*/}
                        {displayTimerOptions && !showTimeInsteadOfTimer && <div className="TimerCommandGauges">
                            <div className="GaugeCell">
                                {/* <Gauge theme={timerActive == 1 ? "dark" : "light"} percentage={Math.round(minuteCountedOfTheStamp/(minuteCountedOfTheStamp + remainingTimeOfTheStamp + 0.0001) * 10) / 10 } value={convertToHourMinute(minuteCountedOfTheStamp, true) } label="TASK" color="yellow"></Gauge> */}
                                <Gauge theme={(!inCountdownToWorkMode && timerActive == 1) ? "dark" : "light"} percentage={Math.round(((timerActive == 1) ? sessionStartTimerHistoryWorkMinutes + (timer1InitialValue - timer1)/60000 : sessionStartTimerHistoryWorkMinutes)/(sessionLength + 0.0001) * 10) / 10 } value={convertToHourMinute((timerActive == 1) ? sessionStartTimerHistoryWorkMinutes + (timer1InitialValue - timer1)/60000 : sessionStartTimerHistoryWorkMinutes, true) } label="SESS" color="yellow"></Gauge>
                            </div>
                            {currentSubSub.expiryDate && <div className="GaugeCell">
                                <div className="BottomRightPanelTitle"><MdCalendarToday></MdCalendarToday></div>
                                <div className={`BottomRightPanelDesc` }><span>{getRemainingDays(currentSubSub.expiryDateStr).toFixed(1)}</span> </div>
                            </div>}
                            {!countUpMode && <div className="GaugeCell" onClick={() => setCountUpMode(!countUpMode)}>
                                <div className="BottomRightPanelTitle"><FaWalking></FaWalking>/<AiOutlineCoffee></AiOutlineCoffee></div>
                                <div className={`BottomRightPanelDesc`}>{Math.floor(defaultTimer1 / (60*1000))}/{Math.floor(defaultTimer2 / (60*1000))}</div>
                            </div>}
                            {countUpMode && <div className="GaugeCell" onClick={() => setCountUpMode(!countUpMode)}>
                                <div className="BottomRightPanelTitle"><BsArrowClockwise></BsArrowClockwise></div>
                                <div className={`BottomRightPanelDesc`}>FWD</div>
                            </div>  
                            }
                            <div className="GaugeCell" style={{alignItems: 'flex-end'}}>
                                <Gauge theme={(!inCountdownToWorkMode && timerActive == 1) ? "dark" : "light"} percentage={Math.round((totalHourOfTheStamp - hourRemainingToDisplay) / (totalHourOfTheStamp + 0.0001) * 10) / 10} value={convertToHourMinute((totalHourOfTheStamp - hourRemainingToDisplay)*60)} label="TOTAL" color={`${imminentCalendarBar == 'imminent' ? 'white' : (imminentCalendarBar == 'active' ? 'cyan' : 'cyan')}`}></Gauge>
                            </div>
                        </div>}
                        
                    </div>
                    
                    {/* End of minHeightTimerLayout */}

                    {(displayTimerOptions && !showTimeInsteadOfTimer) && <div>

                            <div style={{marginTop: 20}}></div>

                            {showProcastinateCountdown && <div className="TimerDurationOptions">
                                <div style={{flex: '0 0 40px'}}><FaRegHourglass></FaRegHourglass></div>
                                <div onClick={() => startCountdownToWorkMemoized(1)} className={`${minutesSetForCountdown == 1 ? "DurationCountdown" : ""}`}>1</div>
                                <div onClick={() => startCountdownToWorkMemoized(2)} className={`${minutesSetForCountdown == 2 ? "DurationCountdown" : ""}`}>2</div>
                                <div onClick={() => startCountdownToWorkMemoized(3)} className={`${minutesSetForCountdown == 3 ? "DurationCountdown" : ""}`}>3</div>
                                <div onClick={() => startCountdownToWorkMemoized(5)} className={`${minutesSetForCountdown == 5 ? "DurationCountdown" : ""}`}>5</div>
                                <div onClick={() => startCountdownToWorkMemoized(10)} className={`${minutesSetForCountdown == 10 ? "DurationCountdown" : ""}`}>10</div>
                                <div onClick={() => startCountdownToWorkMemoized(15)} className={`${minutesSetForCountdown == 15 ? "DurationCountdown" : ""}`}>15</div>
                            </div>}

                            {!countUpMode && <><div className="TimerDurationOptions">
                                <div style={{flex: '0 0 40px'}}><FaWalking></FaWalking></div>
                                <div onClick={() => {setDuration1(5)}} className={`${defaultTimer1 == 5*60*1000 ? "DurationActiveAqua" : ""}`}>5</div>
                                <div onClick={() => {setDuration1(10)}} className={`${defaultTimer1 == 10*60*1000 ? "DurationActiveAqua" : ""}`}>10</div>
                                <div onClick={() => {setDuration1(15)}} className={`${defaultTimer1 == 15*60*1000 ? "DurationActiveAqua" : ""}`}>15</div>
                                <div onClick={() => {setDuration1(25)}} className={`${defaultTimer1 == 25*60*1000 ? "DurationActiveAqua" : ""}`}>25</div>
                                <div onClick={() => {setDuration1(30)}} className={`${defaultTimer1 == 30*60*1000 ? "DurationActiveAqua" : ""}`}>30</div>
                                <div onClick={() => {setDuration1(45)}} className={`${defaultTimer1 == 45*60*1000 ? "DurationActiveAqua" : ""}`}>45</div>
                            </div>
                            <div className="TimerDurationOptions">
                                <div style={{flex: '0 0 40px'}}><AiOutlineCoffee></AiOutlineCoffee></div>
                                <div onClick={() => {setDuration2(0.09)}} className={`${Math.round(defaultTimer2) == Math.round(0.09*60*1000) ? "DurationActiveYellow" : ""}`}>0</div>
                                <div onClick={() => {setDuration2(1)}} className={`${defaultTimer2 == 1*60*1000 ? "DurationActiveYellow" : ""}`}>1</div>
                                <div onClick={() => {setDuration2(3)}} className={`${defaultTimer2 == 3*60*1000 ? "DurationActiveYellow" : ""}`}>3</div>
                                <div onClick={() => {setDuration2(5)}} className={`${defaultTimer2 == 5*60*1000 ? "DurationActiveYellow" : ""}`}>5</div>
                                <div onClick={() => {setDuration2(10)}} className={`${defaultTimer2 == 10*60*1000 ? "DurationActiveYellow" : ""}`}>10</div>
                                <div onClick={() => {setDuration2(15)}} className={`${defaultTimer2 == 15*60*1000 ? "DurationActiveYellow" : ""}`}>15</div>
                                </div></>}
                        </div>}
                    
                    <HomeControlPanel
                    addToTimer={addToTimerMemoized}
                    setFlaggedSubTasks={setFlaggedSubTasks}
                    setFlaggedDoneSubTasks={setFlaggedDoneSubTasks}
                    setShowFlaggedTasksOnly={setShowFlaggedTasksOnly}
                    finalize={finalizeMemoized}
                    toggleCalendar={toggleCalendarMemoized}
                    showProcastScreen={showProcastScreenMemoized}
                    toggleProcrastinate={toggleProcastinateCountdownMemoized}
                    showProcastinateCountdown={showProcastinateCountdown}
                    loadData={loadDataMemoized}
                    markAllFlagsOfCurrentSubSubAsDone={markAllFlagsOfCurrentSubSubAsDoneMemoized}
                    revertDoneFlagsBackToFlagged={revertDoneFlagsBackToFlaggedMemoized}
                    setAllowCalendarAdjustedTimer={setAllowCalendarAdjustedTimer}
                    setCountUpMode={setCountUpMode}
                    setDisplayTimerOptions={setDisplayTimerOptions}
                    allowCalendarAdjustedTimer={allowCalendarAdjustedTimer}
                    countUpMode={countUpMode}
                    showFlaggedTasksOnly={showFlaggedTasksOnly}
                    displayTimerOptions={displayTimerOptions}
                    toggleAutoTodo={toggleAutoTodoMemoized}
                    autoTodo = {autoTodo}
                    allowTimerAdjustForEndOfTask = {allowTimerAdjustForEndOfTask}
                    toggleTimerAdjustForEndOfTask={toggleTimerAdjustForEndOfTaskMemoized}
                    showOnlyNonZerosAndCountUp={showOnlyNonZerosAndCountUp}
                    toggleShowOnlyNonZerosAndCountUp={toggleShowOnlyNonZerosAndCountUpMemoized}
                    ordoscopeVersion={ordoscopeVersion}
                    ></HomeControlPanel>

                    {/* <div className="TimerCommandButtons">
                        <div className="TimerCommandButtonsRows CommandButtonsAux">
                            <Button variant="dark" onClick={() => {addToTimer(1)}}><div className="ButtonAlign"><BsPlusCircle className="ButtonGlyph"></BsPlusCircle><span>1</span></div></Button>
                            <Button variant="dark" onClick={() => {addToTimer(2)}}><div className="ButtonAlign"><BsPlusCircle className="ButtonGlyph"></BsPlusCircle><span>2</span></div></Button>
                            <Button variant="dark" onClick={() => {addToTimer(5)}}><div className="ButtonAlign"><BsPlusCircle className="ButtonGlyph"></BsPlusCircle><span>5</span></div></Button>
                            <Button variant="dark" onClick={() => {addToTimer(10)}}><div className="ButtonAlign"><BsPlusCircle className="ButtonGlyph"></BsPlusCircle><span>10</span></div></Button>
                        </div>
                        <div className="TimerCommandButtonsRows CommandButtonsAux">
                            <Button variant="dark" onClick={() => {addToTimer(-1)}}><div className="ButtonAlign"><BsFillPatchMinusFill className="ButtonGlyph"></BsFillPatchMinusFill><span>1</span></div></Button>
                            <Button variant="dark" onClick={() => {addToTimer(-2)}}><div className="ButtonAlign"><BsFillPatchMinusFill className="ButtonGlyph"></BsFillPatchMinusFill><span>2</span></div></Button>
                            <Button variant="dark" onClick={() => {addToTimer(-5)}}><div className="ButtonAlign"><BsFillPatchMinusFill className="ButtonGlyph"></BsFillPatchMinusFill><span>5</span></div></Button>
                            <Button variant="dark" onClick={() => {addToTimer(-10)}}><div className="ButtonAlign"><BsFillPatchMinusFill className="ButtonGlyph"></BsFillPatchMinusFill><span>10</span></div></Button>
                        </div>
                        <div className="TimerCommandButtonsRows CommandButtonsAux">
                            <Button variant="dark" onClick={() => {dispatch(resetNotifications())}}><div className="ButtonAlign"><BiReset className="ButtonGlyph"></BiReset><span>NTF</span></div></Button>
                            <Button variant="dark" onClick={() => { setFlaggedSubTasks([]); setFlaggedDoneSubTasks([]) }}><div className="ButtonAlign"><BiReset className="ButtonGlyph"></BiReset><span>FLG</span></div></Button>
                            <Button variant="dark" onClick={() => { setShowFlaggedTasksOnly(!showFlaggedTasksOnly) }}><div className="ButtonAlign"><BsFillGrid3X2GapFill className="ButtonGlyph"></BsFillGrid3X2GapFill><span>OFL</span></div></Button>
                            <Button variant="dark" onClick={() => {dispatch(setCalendarUpdateToken())}}><div className="ButtonAlign"><BsFillGrid3X2GapFill className="ButtonGlyph"></BsFillGrid3X2GapFill><span>CLD</span></div></Button>
                        </div>
                        <div className="TimerCommandButtonsRows CommandButtonsAux">
                            <Button variant="dark" onClick={() => {finalize()}}><div className="ButtonAlign"><BsFillCapslockFill className="ButtonGlyph"></BsFillCapslockFill><span>SND</span></div></Button>
                            <Button variant="dark" onClick={() => {toggleCalendar(1)}}><div className="ButtonAlign"><BsFillGrid3X2GapFill className="ButtonGlyph"></BsFillGrid3X2GapFill><span>VW+</span></div></Button>
                            <Button variant="dark" onClick={() => {toggleCalendar(-1)}}><div className="ButtonAlign"><BsFillGrid3X2GapFill className="ButtonGlyph"></BsFillGrid3X2GapFill><span>VW-</span></div></Button>
                            <Button variant="dark" onClick={() => {showProcastScreen()}}><div className="ButtonAlign"><BiTask className="ButtonGlyph"></BiTask><span>TDO</span></div></Button>
                        </div>
                        <div className="TimerCommandButtonsRows CommandButtonsAux">
                            <Button variant={`${!displayTimerOptions ? 'primary' : 'dark'}`} onClick={() => {setDisplayTimerOptions(!displayTimerOptions)}}><div className="ButtonAlign"><BsFillMoonFill className="ButtonGlyph"></BsFillMoonFill><span>FCS</span></div></Button>
                            <Button variant="dark" onClick={() => {setChronometer1(0)}}><div className="ButtonAlign"><BiReset className="ButtonGlyph"></BiReset><span>RSC</span></div></Button>
                            <Button variant="dark" onClick={() => {loadData()}}><div className="ButtonAlign"><BiRefresh className="ButtonGlyph"></BiRefresh><span>LTS</span></div></Button>
                            <Button variant="dark" onClick={() => {markAllFlagsOfCurrentSubSubAsDone()}}><div className="ButtonAlign"><BiCheck className="ButtonGlyph"></BiCheck><span>DST</span></div></Button>
                        </div>
                        <div className="TimerCommandButtonsRows CommandButtonsAux">
                            <Button variant="dark" onClick={() => {revertDoneFlagsBackToFlagged()}}><div className="ButtonAlign"><BiReset className="ButtonGlyph"></BiReset><span>RVF</span></div></Button>
                            <Button variant="dark" onClick={() => {window.location.href = '/'}}><div className="ButtonAlign"><BsFillGrid3X2GapFill className="ButtonGlyph"></BsFillGrid3X2GapFill><span>SPL</span></div></Button>
                            <Button variant={`${allowCalendarAdjustedTimer ? 'primary' : 'dark'}`} onClick={() => {setAllowCalendarAdjustedTimer(!allowCalendarAdjustedTimer)}}><div className="ButtonAlign"><MdTimer className="ButtonGlyph"></MdTimer><span>CTM</span></div></Button>
                            <Button variant={`${countUpMode ? 'primary' : 'dark'}`} onClick={() => {setCountUpMode(!countUpMode)}}><div className="ButtonAlign"><AiFillCaretUp className="ButtonGlyph"></AiFillCaretUp><span>UP</span></div></Button>
                        </div>
                        
                        <div className="TimerCommandButtonsRows">
                            {/* <Button onClick={() => {
                                dispatch(setOverlayNotification({
                                    title: "Test Notification",
                                    message: "This is a test notification",
                                    show: true
                                }))}}>Test overlay notification</Button> */}
                    {/*    </div>
                    </div> */}

                    <div className="Label" style={{textAlign: 'center'}}>Work Sessions</div>
                    <div className="TimerHistory">
                        <TimerControlHistory deleteHistory={deleteHistoryMemoized}
                        timerHistory={timerHistory}></TimerControlHistory>
                    </div>
                </div>
                <div className={`RightPanel Tasks`}>
                    
                    {rightPanelMode == 0 && <><div id="taskPanel" className="FixedHeightTimerLayoutR">

                        { /* Showing active task */}
                        {
                        ((rightPanelMode==0) && (currentSubSub.sname)) && 
                        <div className="SubTaskHome ActiveSubTaskHome">
                            <div className={`Title ${(((timerActive == 1)) ? (currentSubSub.finish * 2 * (25 * 60 * 1000) / 60000 - (timer1InitialValue - timer1)/60000) : (currentSubSub.finish * 2 * (25 * 60 * 1000) / 60000)) < 0 ? "TitleRouge" : ""}`}>
                                <span style={{marginLeft: 5}}><span onClick={deactivateCurrentSubSubMemoized}>{currentSubSub.sname} {(currentSubSub.hasOwnProperty("time") && currentSubSub.countUp!=1) && "• "}</span> <span className={`ElapsedMinutesOfCurrentSubSub ${remainingTimeOfTheStamp < 0 ? 'NegativeHighlight' : ''}`} onClick={makeTimer1RemainingMinutesMemoized} >{(currentSubSub.hasOwnProperty("time") && currentSubSub.countUp!=1) && convertToHourMinute(remainingTimeOfTheStamp)}</span></span>
                                
                            </div>
                            {((currentSubSub.hasOwnProperty("time"))) ? (<ProgressBar style={{marginTop: 7}} now={currentSubSub.finish < 0 ? 100 : 100 - (currentSubSub.finish * 2 * (25 * 60 * 1000) / 60000 - (timer1InitialValue - (  timerActive == 1 ? timer1 : timer1InitialValue  ))/60000) / ((currentSubSub.initialFinish+0.000001) * 2 * (25 * 60 * 1000) / 60000) * 100}></ProgressBar>) : <></>}
                                        
                            <ActiveStamp 
                                currentSubSub={currentSubSub}
                                showFlaggedTasksOnly={showFlaggedTasksOnly}
                                flaggedDoneSubTasks={flaggedDoneSubTasks}
                                flaggedSubTasks={flaggedSubTasks}
                                flagSubTask={memoizedFlagSubTask}
                                setSubtasksHighlighted={setSubtasksHighlighted}
                                setHighlightedSubSubHelperMemoized={setHighlightedSubSubHelperMemoized}
                                memoizedFlagSubTask={memoizedFlagSubTask}
                                subtasksHighlighted={subtasksHighlighted}
                                sortSubSubsWithFlaggedSubSubsFirst={sortSubSubsWithFlaggedSubSubsFirstMemoized}
                                loadTaskHandler={loadData}
                                stampId={stampIdToShow}>
                            </ActiveStamp>


                        </div> }

                        { /* Showing all remaining tasks */}

                        <RemainingTasksHome subtasks={subtasks} currentSubSubsname={currentSubSub.sname}
                        showFlaggedTasksOnly={showFlaggedTasksOnly} flaggedSubTasks={flaggedSubTasks}
                        flaggedDoneSubTasks={flaggedDoneSubTasks} flagSubTask={memoizedFlagSubTask} flaggedSubTasksContainsSname={memoizedFlaggedSubTasksContainsSname}
                        selectSubSub={memoizedSelectSubSub}
                        showOnlyNonZerosAndCountUp={showOnlyNonZerosAndCountUp}
                        flagAllSubTasks={memoizedFlagAllSubTasks}></RemainingTasksHome>


                        {/* <div style={{display: displayTimerOptions ? 'block' : 'none'}}>
                            <HourChart timerHistory={timerHistory} remoteTimerHistory={remoteTimerHistory} ></HourChart>
                        </div> */}

                        



                    </div>



                    {/* <div className="BottomRightPanel">
                        <div className="BottomRightPanelColumn">
                            <div className="BottomRightPanelTitle">TASK <AiOutlineCaretUp></AiOutlineCaretUp></div>
                            <div className={`BottomRightPanelDesc`}>{convertToHourMinute(minuteCountedOfTheStamp)}</div>
                        </div>
                        <div className="BottomRightPanelColumn">
                            {currentSubSub.hasOwnProperty('expiryDate') && 
                            <>
                                <div className="BottomRightPanelTitle">TARGET</div>
                                <div className={`BottomRightPanelDesc`}>
                                    <div className={`BottomRightExpiryDate ${determiningTheClassNameOfExpiryDate(currentSubSub.expiryDate)}`}>{
                                    // days remaining
                                    moment(currentSubSub.expiryDate.toDate()).diff(moment(currentTime), 'days')
                                    }D</div>
                                </div>
                            </>}
                            {!currentSubSub.hasOwnProperty('expiryDate') && 
                            <>
                                <div className="BottomRightPanelTitle">TOTAL <AiOutlineCaretUp></AiOutlineCaretUp></div>
                            <div className={`BottomRightPanelDesc`}>{formatTimerH((totalHourOfTheStamp - hourRemainingToDisplay)*3600*1000)}</div>
                            </>}
                            
                        </div>
                    </div> */}



                    </>

                    
                    }



                    

                    {rightPanelMode == 1 && 
                    <div className='CalendarDayView'>
                        <CalendarDayView theme={timerActive == 1 ? 'dark' : 'white'} height={480}></CalendarDayView>
                    </div>
                    }

                    {/* {rightPanelMode==2 && <DailyCalendarSideBar></DailyCalendarSideBar>} */}

                    {/*rightPanelMode==2 && <PersonalCalendarSideBar todayOnly={false}></PersonalCalendarSideBar>*/}

                    {rightPanelMode == 2 && <div className="AllTaskHomeDiv">
                        <AllTasksNew tasks={tasksToShowOnProcast} taskId={0} loadTaskHandler={loadData}></AllTasksNew>
                    </div>
                    }
                    
                    {//<div className="operationInfo">
                    //    NRT {moment(timeUp).add(2, 'hour').format("H:mm")} - <a onClick={() => {window.location.href= showSeconds ? '/home' : '/homelegacy'}}>RLD</a>
                    //</div>
                    }
                    <div style={{marginTop: 10, marginLeft: 8, marginRight: 8}}>
                        <TaskSwitcher setStampIdToShow={setStampIdToShow} tasksToShowOnProcast={tasksToShowOnProcast}></TaskSwitcher>
                    </div>

                    <div className="ProfilePicRightPanelWrap">
                        <ProfilePic profilePic={profilePic} username={username} balance={balance} ordoscopeVersion={ordoscopeVersion} afterSyncHandler={loadDataMemoized}></ProfilePic>
                    </div>

                    <div className="paymeeco legend"><div style={{fontWeight: 'bold'}}>scopeOS 24.24</div></div>
                    <div className="paymeeco legend"><div style={{fontWeight: 'bold'}}>
                        Ordoscope {ordoscopeVersion}
                    </div></div>
                    
                    {/* <div>{payrate}</div> */}
                    {/* <div>{timer1}</div>
                    <div>{timer1InitialValue}</div>
                    <div>{timer1InitialValue2}</div>
                    <div>{defaultTimer1}</div>
                    <div>
                        <a onClick={swapAndRedirect}>Swap Now</a>
                    </div> */}
                    
                    {/* <div onClick={() => saveToSwap()}>S2S</div>
                    <div onClick={() => window.location.href = '/homeswapped'}>SWAPPED</div> */}
                </div>
            </div>
        </div>
        </div>
        </div>
    )
}

export default Home