import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Home from './Home';
import Ticker from './Ticker'
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import store from './store'
import { Provider } from 'react-redux'
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";
import Edit from './Edit';
import EditAny from './EditAny';
import Splash from './Splash';
import DivWithProgress from './DivWithProgress';
import CalendarPersonalPMM from './CalendarPersonalPMM';
import Backup from './Backup';
import ChecklistTest from './ChecklistTest';
import GaugeTest from './GaugeTest';
import TodoEdit from './TodoEdit';
import Feedback from './Feedback';
import Login from './Login';
import Advice from './Advice'
import CalendarDayView from './CalendarDayView';
import MyForm from './MyForm';
import Scheduler from './Scheduler';
import ReviewCalendarView from './ReviewCalendarView';
const router = createBrowserRouter([
  {
    path: "/",
    element: <Splash></Splash>,
  },
  {
    path: "/home",
    element: <Home showSeconds={true}></Home>
  },
  {
    path: "/homeswapped",
    element: <Home showSeconds={true} swapped={true}></Home>
  },
  {
    path: "/homelegacy",
    element: <Home showSeconds={false}></Home>
  },
  {
    path: "/homelegacyswapped",
    element: <Home showSeconds={false} swapped={true}></Home>
  },
  {
    path: "/edit",
    element: <Edit></Edit>
  },
  {
    path: "/editany",
    element: <EditAny></EditAny>
  },
  {
    path: "/splash",
    element: <Splash></Splash>
  },
  {
    path: "/ticker",
    element: <Ticker></Ticker>
  },
  {
    path: "/progress",
    element: <DivWithProgress></DivWithProgress>
  },
  {
    path: "/pmc",
    element: <CalendarPersonalPMM></CalendarPersonalPMM>
  },
  {
    path: "/backup",
    element: <Backup></Backup>
  },
  {
    path: "/checklist",
    element: <ChecklistTest></ChecklistTest>
  },
  {
    path: "/gauge",
    element: <GaugeTest></GaugeTest>
  },
  {
    path: "/todoedit",
    element: <TodoEdit></TodoEdit>
  },
  {
    path: "/testfeedback",
    element: <Feedback datetime="2023-12-24 01:02:03" taskId="TEST1" duration={25}></Feedback>
  },
  {
    path: "/login",
    element: <Login></Login>
  },
  {
    path: "/advice",
    element: <Advice></Advice>
  },
  {
    path: '/testcalendardayview',
    element: <CalendarDayView></CalendarDayView>
  },
  {
    path: '/form',
    element: <MyForm></MyForm>
  },
  {
    path: '/schedule',
    element: <Scheduler></Scheduler>
  },
  {
    path: '/review',
    element: <ReviewCalendarView></ReviewCalendarView>
  }
]);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
